import { FC, useState } from "react";
import { RELATIONSHIP_ITEM_PER_PAGE, USER_CACHE_KEYS } from "../../common";
import { useQuery } from "react-query";
import { getFinanceAccounts } from "../../common/api/users";
import { FinanceAccounts } from "./FinanceAccounts";

export const UserFinanceAccounts: FC<{ userId: number }> = (props) => {
  const { userId } = props;
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(
    [USER_CACHE_KEYS.FINANCE_ACCOUNTS(userId), page],
    () =>
      getFinanceAccounts(userId, {
        itemsPerPage: RELATIONSHIP_ITEM_PER_PAGE,
        page,
      }),
    {
      keepPreviousData: true,
    }
  );

  return (
    <FinanceAccounts
      dataSource={data?.data.data.relation_data.data || []}
      loading={isLoading}
      onPageChange={setPage}
      total={data?.data.data.relation_data.total || 0}
    />
  );
};
