import { FC, useState } from "react";
import {
  Dropdown,
  Layout as AntLayout,
  Menu,
  Avatar,
  Typography,
  Image,
  message,
  Space,
  AutoComplete,
} from "antd";
import {
  DownOutlined,
  FireOutlined,
  UserOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons";
import { ROUTES, WHITE_LOGO } from "../constants";
import { logout } from "../api/auth";
import { Link, useLocation, useNavigate } from "@reach/router";
import { useUser } from "../../auth/components/UserContext";
import { useConfig } from "../../auth/components/ConfigContext";

const { Sider, Header, Content, Footer } = AntLayout;

const PADDING_X = "24px";

export const Layout: FC<{ title?: string }> = (props) => {
  const { title = "Pulpo" } = props;
  const { user, deleteUser } = useUser();
  const { countries, currentCountry, setCurrentCountry } = useConfig();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = async () => {
    const hide = message.loading("Logging you out...");
    try {
      await logout();
      deleteUser();
      navigate("/");
    } catch (e) {
      message.error("Error in logging out!");
    } finally {
      hide();
    }
  };
  const sidebarWidth = collapsed ? 80 : 200;
  return (
    <AntLayout style={{ background: "transparent" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
        collapsed={collapsed}
        collapsible
        onCollapse={setCollapsed}
        collapsedWidth={80}
      >
        {/* TODO: Use a white colored logo */}
        <Image src={WHITE_LOGO} width="100%" alt="logo" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          onClick={(info) => navigate(String(info.key))}
        >
          <Menu.Item key="/platforms" icon={<FireOutlined />}>
            Platforms
          </Menu.Item>
          <Menu.Item key="/users" icon={<UserOutlined />}>
            Users
          </Menu.Item>
          <Menu.Item key="/bulk-tagging" icon={<InsertRowBelowOutlined />}>
            Bulk Tagging
          </Menu.Item>
          {/* <Menu.Item key="/groups" icon={<SwitcherOutlined />}>
            Groups
          </Menu.Item> */}
        </Menu>
      </Sider>
      <AntLayout
        style={{ marginLeft: sidebarWidth, background: "transparent" }}
      >
        <Header
          style={{
            paddingLeft: PADDING_X,
            paddingRight: PADDING_X,
            background: "white",
            position: "fixed",
            zIndex: 1,
            width: `calc(100% - ${sidebarWidth}px)`,
            boxShadow: "0px 1px 3px rgba(0, 0, 0, .12)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={4} style={{ margin: 0 }}>
            {title}
          </Typography.Title>
          <Space direction="horizontal" size="middle">
            <GlobalSearch />
            <Dropdown
              overlay={
                <Menu>
                  {countries.map((country) => (
                    <Menu.Item onClick={() => setCurrentCountry(country.id)}>
                      {country.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Typography.Text style={{ margin: 0 }}>
                {currentCountry.name} <DownOutlined />
              </Typography.Text>
            </Dropdown>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
                </Menu>
              }
            >
              <Avatar
                style={{
                  backgroundColor: "#001529",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                size="large"
              >
                {user?.firstName[0].toUpperCase() || "G"}
              </Avatar>
            </Dropdown>
          </Space>
        </Header>
        <Content
          style={{
            marginTop: 64,
            overflow: "initial",
            minHeight: "100vh",
            padding: `12px ${PADDING_X}`,
          }}
        >
          {props.children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <a href="mailto:it@pulpo.so">For any issues: it@pulpo.so</a>
        </Footer>
      </AntLayout>
    </AntLayout>
  );
};

const GlobalSearch: FC = (props) => {
  const INVALID_ENTRY = "";
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const handleSearch = (value: string) => {
    setSearchValue(value);
    const parsed = Number(value);
    if (!Number.isNaN(parsed)) {
      if (parsed > 0) {
        setOptions([
          {
            label: `See User with ID: ${value}`,
            value: ROUTES.getUserDetailsRoute(value),
          },
          {
            label: `See Group with ID: ${value}`,
            value: ROUTES.getGroupDetailsRoute(value),
          },
        ]);
      } else {
        setOptions([
          {
            label: "Enter a valid search item",
            value: INVALID_ENTRY,
          },
        ]);
      }
    } else {
      setOptions([
        {
          label: `See Group with MASK: ${value}`,
          value: ROUTES.getGroupDetailsRoute(value),
        },
      ]);
    }
  };
  const handleSelect = (value: string) => {
    if (value !== INVALID_ENTRY) {
      navigate(value);
      setSearchValue("");
    }
  };
  return (
    <AutoComplete
      style={{ width: 300 }}
      placeholder="Search User / Group..."
      onChange={handleSearch}
      onSelect={handleSelect}
      autoFocus
      dropdownMatchSelectWidth={false}
      value={searchValue}
    >
      {options.map((option) => (
        <AutoComplete.Option value={option.value}>
          <Link to="/users">{option.label}</Link>
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};
