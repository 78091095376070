import { Button, Tooltip, Modal, notification } from "antd";
import { FC } from "react";
import { APIError, changeRemoveMember } from "../../common";
import { RetweetOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

export const ChangeGroupButton: FC<{
  memberId: number;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  groupMask: string;
}> = (props) => {
  const { memberId, onSuccess, onError, groupMask } = props;
  const { mutate: changeRemove, isLoading } = useMutation(changeRemoveMember);
  const handleChangeGroup = () => {
    Modal.confirm({
      title: "Are you sure you want to change group?",
      content: "NOTE: System will automatically find a suitable group",
      onOk: () => {
        changeRemove(
          {
            forceLeaving: false,
            mask: groupMask,
            trigger: "afe_staff_action",
            changeGroup: true,
            stopIfNoAvailableGroup: true,
            groupMemberId: memberId,
          },
          {
            onSuccess: () => {
              onSuccess?.();
            },
            onError: (e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.errorCode,
              });
              onError?.(e);
            },
          }
        );
      },
    });
  };
  return (
    <Tooltip title="Change Group">
      <Button
        icon={<RetweetOutlined />}
        onClick={handleChangeGroup}
        shape="circle"
        loading={isLoading}
      />
    </Tooltip>
  );
};
