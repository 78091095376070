import { Tabs } from "antd";
import { FC } from "react";
import { UserFinanceTransactions } from "./UserFinanceTransactions";
import { UserFinanceAccounts } from "./UserFinanceAccounts";
import { UserGroupActions } from "./UserGroupActions";
import { UserCommunications } from "./UserCommunications";
import { UserGroups } from "./UserGroups";
import { GroupsAsOwner } from "./GroupsAsOwner";
import { PaymentMethods } from "./PaymentMethods";

export const UserDetailedData: FC<{ userId: number }> = (props) => {
  const { userId } = props;

  return (
    <Tabs>
      {USER_DETAILED_TABS.map((item) => (
        <Tabs.TabPane tab={item.label} key={item.key}>
          <item.Component userId={userId} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
const USER_DETAILED_TABS = [
  {
    label: "User Groups",
    Component: UserGroups,
    key: 1,
  },
  {
    label: "Communications",
    Component: UserCommunications,
    key: 2,
  },
  {
    label: "Groups As Owner",
    Component: GroupsAsOwner,
    key: 3,
  },
  {
    label: "User Actions",
    Component: UserGroupActions,
    key: 4,
  },
  {
    label: "Payment Methods",
    Component: PaymentMethods,
    key: 5,
  },
  {
    label: "Finance Accounts",
    Component: UserFinanceAccounts,
    key: 6,
  },
  {
    label: "Finance Transactions",
    Component: UserFinanceTransactions,
    key: 7,
  },
];
