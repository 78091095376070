import { notification } from "antd";
import axios from "redaxios";
import { USER_STORAGE_KEY } from "./constants";
import { captureException } from "./exceptionHandler";
import { forceLogout } from "./utils";

type ErrorCode = "wrong_credentials";

type AxiosSuccessResponse<T> = {
  data: T;
};

type AxiosSerializerErrorResponse = {
  errors: {
    error_code: number;
    message: string;
    validator: Record<string, string[]>;
  };
};

type AxiosBusinessErrorResponse = {
  errors: { errorCode: ErrorCode; message: string };
};

type AxiosErrorResponse = AxiosBusinessErrorResponse &
  AxiosSerializerErrorResponse;

export type APIError = {
  data?: AxiosErrorResponse;
  config: Parameters<typeof axios>[0];
  status: number;
  statusText: string;
  ok: boolean;
  type: string;
  url: string;
};

const API_GUARD = "/api/afe";
export function pulpoAPI<SuccessData = any>(
  ...params: Parameters<typeof axios>
) {
  const axiosInstance: typeof axios = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}${API_GUARD}`,
    headers: {
      "api-client-id": process.env.REACT_APP_CLIENT_ID!,
    },
  });
  return axiosInstance<AxiosSuccessResponse<SuccessData>>(...params).catch((error: Response) => {
    captureException(error, `Error in API`);

    // Do not do global logout on login URL
    // Showing a toast should be just fine!
    // Else the UX is bad since this API gives 401 
    // in case of wrong creds, which is correct
    // but the user is redirected to the homepage
    // @ts-expect-error
    const isLoginURL = params[0].url.includes("/login");
    if (!isLoginURL && error.status === 401) {
      notification.warn({
        message: "Logging out...",
      })
      // Deferring this by 1 sec
      // User needs at least 1 sec to read the message
      // Since forceLogout clears the memory
      // downloads the new bundle
      // and remove all the timers from the event queue
      // thus removing all the DOM elements and also the toast 
      setTimeout(() => {
        forceLogout()
      }, 1000);
    }

    if (!isLoginURL && error.status === 403) {
      notification.warn({
        message: "Insufficient Permissions",
      })
    }

    // Throwing this back for the caller 
    // to check exact error code when the status is not 401
    throw error;
  });
}

// Re-exporting this to avoid the dependency on this lib
// throughout the code
// This is just to make API calls outside the pulpo env
// a rare case scenario
export const pulpoNetwork = axios;

export const getAuthHeader = () => {
  // Hack(y) way to fetch token
  // Since we are using createPersistedState
  // and it manages the type of storage internally
  // & accessing localStorage manually might lead to breakages in future
  let token = null;
  try {
    token = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || "").token;
  } catch (e) {
    token = null;
  }

  if (!token) {
    return undefined;
  } else {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
};

// Env Validations
if (!process.env.REACT_APP_CLIENT_ID) {
  throw new Error("Could not find REACT_APP_CLIENT_ID in env");
}
if (!process.env.REACT_APP_API_BASE_URL) {
  throw new Error("Could not find REACT_APP_API_BASE_URL in env");
}
