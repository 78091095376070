import { RouteComponentProps } from "@reach/router";
import { FC, useState } from "react";
import { Layout } from "../../common/components/Layout";
import { message, Table, Button, PageHeader, Modal } from "antd";
import { useQuery } from "react-query";
import { getPlatforms } from "../../common/api/platform";
import { APIError, Platform } from "../../common";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useColumnSearch } from "../../common/hooks/useColumnSearch";
import { PlatformForm } from "./PlatformForm";
import { useConfig } from "../../auth/components/ConfigContext";
import { queryClient } from "../../queryUtils";

export const PlatformsList: FC<RouteComponentProps> = (props) => {
  // TODO: Can platform and mode be handled using a single var?
  const [modalMode, setModalMode] = useState<"create" | "edit" | null>(null);
  const [currentPlatform, setCurrentPlatform] = useState<number>(0);
  const { countryId } = useConfig();
  const { status, data: platforms } = useQuery(
    ["platforms", countryId],
    () => getPlatforms(countryId),
    {
      staleTime: 5 * 60 * 60 * 1000,
      onError: (error: APIError) => {
        message.error(error.data?.errors.message);
      },
    }
  );
  const getConfigForNameSearch = useColumnSearch<Platform>("name");
  const handleFormFinish = () => {
    setModalMode(null);
    queryClient.invalidateQueries(["platforms", countryId], { exact: true });
  };
  return (
    <Layout>
      <Modal
        width="60%"
        visible={modalMode !== null}
        footer={null}
        onCancel={() => setModalMode(null)}
        title="Create a new Platform"
        destroyOnClose={true}
      >
        <PlatformForm
          // @ts-expect-error
          mode={modalMode}
          platformId={currentPlatform}
          onFinish={handleFormFinish}
        />
      </Modal>
      <PageHeader
        style={{ paddingLeft: 0, paddingRight: 0 }}
        title="Platforms"
        extra={[
          <Button
            onClick={() => setModalMode("create")}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create New
          </Button>,
        ]}
      />
      <Table
        loading={
          status === "loading"
            ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
            : false
        }
        dataSource={platforms}
        pagination={false}
        columns={[
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            sorter: true,
            width: "40%",
            ...getConfigForNameSearch(),
          },
          {
            title: "Active",
            key: "active",
            dataIndex: "active",
            render: (value) => (value ? "Yes" : "No"),
            defaultSortOrder: "descend",
            sorter: (a, b) => Number(a.active) - Number(b.active),
          },
          {
            title: "Allow Creation",
            key: "allowGroupsCreation",
            dataIndex: "allowGroupsCreation",
            render: (value) => (value ? "Yes" : "No"),
            sorter: (a, b) =>
              Number(a.allowGroupsCreation) - Number(b.allowGroupsCreation),
          },
          {
            title: "Allow Enrollment",
            key: "allowGroupsEnrollment",
            dataIndex: "allowGroupsEnrollment",
            render: (value) => (value ? "Yes" : "No"),
            sorter: (a, b) =>
              Number(a.allowGroupsEnrollment) - Number(b.allowGroupsEnrollment),
          },
          {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
            render: (value, platform) => (
              <Button
                type="link"
                onClick={() => {
                  setCurrentPlatform(platform.id);
                  setModalMode("edit");
                }}
              >
                Edit
              </Button>
            ),
          },
        ]}
      />
    </Layout>
  );
};
