import { RouteComponentProps } from "@reach/router";
import { Space, Form, Input, Button, Typography, notification } from "antd";
import { FC } from "react";
import { useMutation } from "react-query";
import { APIError, bulkTagging as bulkTaggingAPI, Layout } from "../../common";

export const BulkTagging: FC<RouteComponentProps> = (props) => {
  const [form] = Form.useForm();
  const { mutate: bulkTagging, isLoading } = useMutation(bulkTaggingAPI, {
    onError: (e) => {
      const error = e as APIError;
      if (
        error.data?.errors.validator &&
        Object.keys(error.data?.errors.validator).length
      ) {
        form.setFields(
          Object.keys(error.data?.errors.validator).map((key) => ({
            name: key,
            errors: error.data?.errors.validator[key],
          }))
        );
      } else {
        notification.error({
          message: error.data?.errors.message,
        });
      }
    },
    onSuccess: (response) => {
      notification.success({
        message: "Success",
        description: response.data.data.message,
        duration: null,
      });
      form.resetFields();
    },
  });
  const handleFinish = (values: any) => {
    bulkTagging({
      tag: values.tag,
      users_ids: values.users_ids,
    });
  };
  return (
    <Layout>
      <Space direction="vertical" size="large">
        <Typography.Title level={4}>
          Assign one tag to multiple users at once
        </Typography.Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          requiredMark
          validateMessages={{ required: "This is required" }}
          size="large"
          style={{ width: "700px" }}
        >
          <Form.Item
            rules={[{ required: true }]}
            required
            label="Tag"
            name="tag"
          >
            <Input placeholder="Tag" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true }]}
            required
            name="users_ids"
            label="User Ids"
          >
            <Input.TextArea
              rows={10}
              style={{ width: "100%" }}
              placeholder="User Ids"
            />
          </Form.Item>

          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </Layout>
  );
};
