import * as Sentry from "@sentry/react";
import { USER_STORAGE_KEY } from "./constants";
import { getItem } from "./storage";
import { User } from "./types";

export const captureException = (error: any, message?: string) => {
  const user = getItem<User>(USER_STORAGE_KEY);
  const commonTags = { isAuthenticated: !!user, message };
  const tags = { ...user, ...commonTags } || commonTags;
  Sentry.captureException(error, {
    tags: {
      ...tags
    }
  })
}