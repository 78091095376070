import { Typography, Table, Tag, Space } from "antd";
import { FC, useState } from "react";
import {
  formatDate,
  GroupMemberPeriod,
  UserGroupRelation as GroupMembersType,
  RELATIONSHIP_ITEM_PER_PAGE,
  getCommonExpandConfig,
} from "../../common";
import { useConfig } from "../../auth/components/ConfigContext";
import { UserId } from "./UserId";
import { GroupId } from "./GroupId";
import { FinishMemberButton } from "./FinishMemberButton";
import { ChangeGroupButton } from "./ChangeGroupButton";
import { LeaveMemberButton } from "./LeaveMemberButton";
import { ReactivateMemberButton } from "./ReactivateMemberButton";
import { ForceChargeButton } from "./ForceChargeButton";
import { AcceptMemberButton } from "./AcceptMemberButton";
import { RejectMemberButton } from "./RejectMemberButton";

export const Members: FC<{
  dataSource: GroupMembersType[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
  onActionSuccess?: () => void;
}> = (props) => {
  const { dataSource, loading, onPageChange, total, onActionSuccess } = props;
  const [periodData, setPeriodData] = useState<GroupMemberPeriod[]>([]);
  const [expandedRow, setExpandedRow] = useState<number>(0);
  const { formatCurrency } = useConfig();
  const expandedRowRender = () => {
    return (
      <Table
        dataSource={periodData}
        pagination={false}
        columns={[
          {
            title: "Period Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: GroupMemberPeriod["status"]) => (
              <Tag color={value === "paid" ? "success" : "default"}>
                {value.toUpperCase()}
              </Tag>
            ),
          },
          {
            title: "Slot Price",
            dataIndex: "slot_price",
            key: "slot_price",
            render: (value: GroupMemberPeriod["slot_price"]) => (
              <Typography.Text>{formatCurrency(value)}</Typography.Text>
            ),
            // width: "400px",
          },
          {
            title: "Slot Paid",
            dataIndex: "slot_paid",
            key: "slot_paid",
            render: (value: GroupMemberPeriod["slot_paid"]) => (
              <Typography.Text>{formatCurrency(value)}</Typography.Text>
            ),
            // width: "400px",
          },
          {
            title: "Slot Fee",
            dataIndex: "slot_fee",
            key: "slot_fee",
            render: (value: GroupMemberPeriod["slot_fee"]) => (
              <Typography.Text>{formatCurrency(value)}</Typography.Text>
            ),
          },
          {
            title: "Start At",
            dataIndex: "start_at",
            key: "sent_at",
            render: (value: GroupMemberPeriod["start_at"]) =>
              value ? formatDate(value) : "--",
          },
          {
            title: "End At",
            dataIndex: "end_at",
            key: "end_at",
            render: (value: GroupMemberPeriod["end_at"]) =>
              value ? formatDate(value) : "--",
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: GroupMemberPeriod["created_at"]) =>
              formatDate(value),
          },
        ]}
      />
    );
  };
  return (
    <Table
      dataSource={dataSource}
      loading={loading}
      scroll={{ x: true }}
      expandable={{
        ...getCommonExpandConfig<GroupMembersType>(),
        expandedRowRender,
        onExpand: (expanded, record) => {
          if (expanded) {
            setPeriodData(record.group_member_period);
            setExpandedRow(record.id);
          } else {
            setPeriodData([]);
            setExpandedRow(0);
          }
        },
        expandedRowKeys: [expandedRow],
      }}
      rowKey="id"
      columns={[
        {
          title: "User",
          dataIndex: "user",
          key: "user",
          render: (value: GroupMembersType["user"]) => (
            <UserId
              value={value.id}
              firstName={value.first_name}
              lastName={value.last_name || undefined}
            />
          ),
          width: "150px",
        },
        {
          title: "Member Id",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Group",
          dataIndex: "group",
          key: "group",
          render: (value: GroupMembersType["group"]) => (
            <GroupId value={value.id} mask={value.mask} />
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (value: GroupMembersType["status"]) => (
            <Tag color={value === "active" ? "success" : "default"}>
              {value.toUpperCase()}
            </Tag>
          ),
        },
        {
          title: "Platform",
          dataIndex: "platform",
          key: "platform",
          render: (value: GroupMembersType["platform"]) => (
            <Typography.Text>{value.name}</Typography.Text>
          ),
        },
        {
          title: "User Payment Method ID",
          dataIndex: "user_payment_method_id",
          key: "user_payment_method_id",
        },
        {
          title: "Slot Price",
          dataIndex: "slot_price",
          key: "slot_price",
          render: (value: GroupMembersType["slot_price"]) => (
            <Typography.Text>{formatCurrency(value)}</Typography.Text>
          ),
          // width: "400px",
        },
        {
          title: "Slot Fee",
          dataIndex: "slot_fee",
          key: "slot_fee",
          render: (value: GroupMembersType["slot_fee"]) => (
            <Typography.Text>{formatCurrency(value)}</Typography.Text>
          ),
        },
        {
          title: "Start At",
          dataIndex: "start_at",
          key: "sent_at",
          render: (value: GroupMembersType["start_at"]) =>
            value ? formatDate(value) : "--",
        },
        {
          title: "End At",
          dataIndex: "end_at",
          key: "end_at",
          render: (value: GroupMembersType["end_at"]) =>
            value ? formatDate(value) : "--",
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          key: "created_at",
          render: (value: GroupMembersType["created_at"]) => formatDate(value),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          render: (value: any, record) => {
            return (
              <Space direction="horizontal" size="large">
                {record.allowed_actions.includes("group_member_remove") && (
                  <ChangeGroupButton
                    memberId={record.id}
                    onSuccess={onActionSuccess}
                    groupMask={record.group.mask}
                  />
                )}
                {record.allowed_actions.includes("group_member_leaving") && (
                  <LeaveMemberButton
                    memberId={record.id}
                    groupMask={record.group.mask}
                    onSuccess={onActionSuccess}
                  />
                )}
                {record.allowed_actions.includes("group_member_remove") && (
                  <FinishMemberButton
                    memberId={record.id}
                    groupMask={record.group.mask}
                    onSuccess={onActionSuccess}
                  />
                )}
                {record.allowed_actions.includes("group_member_reactivate") && (
                  <ReactivateMemberButton
                    memberId={record.id}
                    groupMask={record.group.mask}
                    onSuccess={onActionSuccess}
                  />
                )}
                {record.allowed_actions.includes(
                  "group_member_manual_charge_unpaid"
                ) && (
                  <ForceChargeButton
                    memberId={record.id}
                    groupMask={record.group.mask}
                    onSuccess={onActionSuccess}
                  />
                )}
                {record.allowed_actions.includes(
                  "group_member_booking_confirm"
                ) && (
                  <AcceptMemberButton
                    memberId={record.id}
                    groupMask={record.group.mask}
                    onSuccess={onActionSuccess}
                  />
                )}
                {record.allowed_actions.includes(
                  "group_member_booking_cancel"
                ) && (
                  <RejectMemberButton
                    memberId={record.id}
                    groupMask={record.group.mask}
                    onSuccess={onActionSuccess}
                  />
                )}
              </Space>
            );
          },
        },
      ]}
      pagination={{
        total,
        pageSize: RELATIONSHIP_ITEM_PER_PAGE,
      }}
      onChange={(pagination) =>
        pagination.current && onPageChange(pagination.current)
      }
    />
  );
};
