import { TagProps } from "antd/lib/tag";
import { UnhandledError } from "./errors";
import { clearStorage } from "./storage";
import { GroupStatus } from "./types";
import { isValid, format } from "date-fns"
import { es } from "date-fns/locale";

export const currencyFormatter = (language: string, currency: string) => (
  value: number | undefined
) => {
  try {
    const formatter = new Intl.NumberFormat(
      convertBELanguageToLocal(language),
      {
        style: "currency",
        maximumFractionDigits: 2,
        currency: currency,
      }
    );
    return formatter.format(Number(value));
  } catch (e) {
    return String(value);
  }
};


export const currencyParser = (language: string, currency: string) => (
  value: string | undefined
) => {
  const locale = convertBELanguageToLocal(language);
  return value ? parseLocaleAwareNumbers(locale, currency, value) : 0
};

export const toCapital = (str: string) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};

export const distinct = <T>(arr: T[]) => {
  return Array.from(new Set(arr));
};


// https://observablehq.com/@mbostock/localized-number-parsing
export function parseLocaleAwareNumbers(locale: string, currency: string, numberString: string) {
  const parts = new Intl.NumberFormat(locale, {
    style: "currency",
    maximumFractionDigits: 2,
    currency: currency,
  }).formatToParts(12345.6);
  // const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210).split("")].reverse();
  const group = new RegExp(`[${parts.find(d => d.type === "group")?.value}]`, "g");
  const decimal = new RegExp(`[${parts.find(d => d.type === "decimal")?.value}]`);
  const currencyGroup = new RegExp(`[${parts.find(d => d.type === "currency")?.value}]`);
  const parsedNumberString = numberString.trim()
    .replace(group, "")
    .replace(currencyGroup, "")
    .replace(decimal, ".")
  return parsedNumberString ? +parsedNumberString : NaN;
}

export function convertBELanguageToLocal(language: string) {
  return language.replace("_", "-");
}


export const forceLogout = () => {
  clearStorage();
  window.location.pathname = "/";
}

export const makeBooleanReadable = (value: boolean) => {
  return value ? "Yes" : "No"
}

// NOTE: This assumes that amount will always be of the format
// "n1....nN.d1d2d3d4" => There always be 4 digits after the decimal
export function formatAmountWithoutLocale(amount: string) {
  return amount.slice(0, amount.length - 2);
}

export function getGroupStatusColor(
  status: GroupStatus
): NonNullable<TagProps["color"]> {
  switch (status) {
    case "new":
      return "geekblue";
    case "booking":
    case "validating":
      return "warning";
    case "incident":
      return "error";
    case "active":
      return "success";
    case "finished":
      return "default";
    default:
      throw new UnhandledError(status, `getGroupStatusColor: ${status}`);
  }
}

export const formatDate = (dateString: string) => {
  // const FORMAT = "MMM dd yyyy";
  const date = new Date(dateString);
  if (isValid(date)) {
    return format(date, "Pp", {
      locale: es
    });
  } else {
    return dateString;
  }
}

export function b64DecodeUnicode(encoded: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(encoded).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}