import { createContext, FC, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import {
  ContextNotFoundError,
  UserAllowedActions,
  UserDetails as UserDetailsType,
} from "../../common";
import { getUserDetails } from "../../common/api/users";

const UserDetailsContext = createContext<UserContextType | undefined>(
  undefined
);
export const UserDetailsProvider: FC<{ userId: number }> = (props) => {
  const { userId } = props;

  const { data, isLoading, error, refetch } = useQuery(`user-${userId}`, () =>
    getUserDetails(userId)
  );

  const contextValue: UserContextType = useMemo(
    () => ({
      user: data?.userDetails,
      allowedActions: data?.allowedActions || [],
      isLoading,
      error,
      refetch,
    }),
    [data?.allowedActions, data?.userDetails, error, isLoading, refetch]
  );

  return (
    <UserDetailsContext.Provider value={contextValue}>
      {props.children}
    </UserDetailsContext.Provider>
  );
};
type UserContextType = {
  user: UserDetailsType | undefined;
  allowedActions: UserAllowedActions[];
  isLoading: boolean;
  error: any;
  refetch: () => void;
};
export const useUserDetails = () => {
  const contextValue = useContext(UserDetailsContext);

  if (contextValue === undefined) {
    throw new ContextNotFoundError("UserDetailsContext");
  }

  return contextValue;
};
