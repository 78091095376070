import { Button, Tooltip, Modal, notification } from "antd";
import { FC } from "react";
import {
  APIError,
  reactivateMember as reactivateMemberAPI,
} from "../../common";
import { CheckOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

export const ReactivateMemberButton: FC<{
  memberId: number;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  groupMask: string;
}> = (props) => {
  const { memberId, onSuccess, onError, groupMask } = props;
  const { mutate: reactivateMember, isLoading } =
    useMutation(reactivateMemberAPI);
  const handleReactivate = () => {
    Modal.confirm({
      title: "Are you sure, you want to reactivate the member?",
      content: "NOTE: Communications will be sent to the member.",
      onOk: () => {
        reactivateMember(
          {
            mask: groupMask,
            memberId,
          },
          {
            onSuccess: () => {
              onSuccess?.();
            },
            onError: (e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.errorCode,
              });
              onError?.(e);
            },
          }
        );
      },
    });
  };
  return (
    <Tooltip title="Reactivate Member">
      <Button
        icon={<CheckOutlined />}
        shape="circle"
        onClick={handleReactivate}
        loading={isLoading}
      />
    </Tooltip>
  );
};
