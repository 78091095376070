import { Tabs } from "antd";
import { FC } from "react";
import { Credentials } from "./Credentials";
import { GroupCommunications } from "./GroupCommunications";
import { GroupGroupActions } from "./GroupGroupActions";
import { GroupMembers } from "./GroupMembers";

export const GroupDetailedData: FC<{
  mask: string;
  disableCredentialsTab: boolean;
}> = (props) => {
  const { mask, disableCredentialsTab } = props;
  return (
    <Tabs>
      {GROUP_DETAILED_TABS.filter((tabItem) =>
        disableCredentialsTab ? tabItem.identifier !== "credentials" : true
      ).map((item) => (
        <Tabs.TabPane tab={item.label} key={item.key}>
          <item.Component identifier={mask} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
const GROUP_DETAILED_TABS = [
  {
    label: "Members",
    Component: GroupMembers,
    key: 1,
    identifier: "members",
  },
  {
    label: "Communications",
    Component: GroupCommunications,
    key: 2,
    identifier: "communications",
  },
  {
    label: "Actions",
    Component: GroupGroupActions,
    key: 3,
    identifier: "actions",
  },
  {
    label: "Credentials",
    Component: Credentials,
    key: 4,
    identifier: "credentials",
  },
];
