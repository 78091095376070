import { pulpoAPI } from "../fetch";
import { Country } from "../types";

export const getConfig = async () => {
  const response = await pulpoAPI<{
    countries: any[];
  }>({
    method: "GET",
    url: `/v1/countries`,
  });
  const modeledResponse: { countries: Country[] } = {
    countries: response.data.data.countries.map((country) => ({
      id: country.id,
      shortCode: country.shortcode,
      name: country.name,
      languages: country.languages,
      defaultLanguage: country.default_language,
      defaultCurrency: country.default_currency,
      defaultCurrencySymbol: country.default_currency_symbol,
      defaultCurrencyDecimalVisuals: country.default_currency_decimals_visual,
      defaultCurrencyDecimalLegal: country.default_currency_decimals_legal,
      defaultTimezone: country.default_timezone,
      defaultPaymentGateway: country.default_paygw,
      verticalsAvailable: country.verticals_available
    })),
  };
  return modeledResponse;
};