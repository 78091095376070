import { FC, useState } from "react";
import { useQuery } from "react-query";
import {
  getGroupActions as getGroupGroupActions,
  GROUP_CACHE_KEYS,
  RELATIONSHIP_ITEM_PER_PAGE,
} from "../../common";
import { GroupActions } from "./GroupActions";

export const GroupGroupActions: FC<{ identifier: string }> = (props) => {
  const { identifier } = props;
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(
    [GROUP_CACHE_KEYS.ACTIONS(identifier), page],
    () =>
      getGroupGroupActions(identifier, {
        itemsPerPage: RELATIONSHIP_ITEM_PER_PAGE,
        page,
      }),
    {
      keepPreviousData: true,
    }
  );

  return (
    <GroupActions
      dataSource={data?.data.data.relation_data.data || []}
      loading={isLoading}
      onPageChange={setPage}
      total={data?.data.data.relation_data.total || 0}
    />
  );
};
