import { message, Spin, Row } from "antd";
import { createContext, FC, useCallback, useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  getConfig,
  APIError,
  Country,
  ContextNotFoundError,
} from "../../common";
import { LoadingOutlined } from "@ant-design/icons";
import {
  convertBELanguageToLocal,
  formatAmountWithoutLocale,
} from "../../common/utils";

const ConfigContext = createContext<
  | {
      countries: Country[];
      currentCountry: Country;
      setCurrentCountry: (id: number) => void;
      loading: boolean;
      formatCurrency: (
        amount: string,
        countryId?: number,
        currency?: string
      ) => string;
    }
  | undefined
>(undefined);

export const ConfigProvider: FC = (props) => {
  const [currentCountry, setCurrentCountry] = useState<Country>();
  const { status, data } = useQuery("config", () => getConfig(), {
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (error: APIError) => {
      message.error("Error in fetching config/countries");
    },
    onSuccess: (response) => {
      setCurrentCountry(response.countries[0]);
    },
  });

  const formatCurrency = useCallback(
    (amount: string, countryId?: number, currency?: string) => {
      const country = countryId
        ? data?.countries.find((country) => country.id === countryId) ||
          currentCountry
        : currentCountry;
      if (country) {
        try {
          const formatter = new Intl.NumberFormat(
            convertBELanguageToLocal(country?.defaultLanguage),
            {
              style: "currency",
              maximumFractionDigits: country.defaultCurrencyDecimalVisuals,
              currency: currency || country.defaultCurrency,
            }
          );
          return formatter.format(Number(amount));
        } catch (e) {
          return formatAmountWithoutLocale(amount);
        }
      } else {
        return formatAmountWithoutLocale(amount);
      }
    },
    [currentCountry, data?.countries]
  );

  if (!currentCountry) {
    return (
      <Row justify="center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </Row>
    );
  }

  const contextValue = {
    countries: data?.countries || [],
    loading: status === "loading",
    currentCountry,
    formatCurrency,
    setCurrentCountry: (id: number) => {
      const country = data?.countries.find((country) => country.id === id);
      if (!country) {
        message.warn("Invalid country ID");
      } else {
        setCurrentCountry(country);
      }
    },
  };

  return (
    <ConfigContext.Provider value={contextValue}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const contextValue = useContext(ConfigContext);
  if (!contextValue) {
    throw new ContextNotFoundError("Config");
  }
  return {
    ...contextValue,
    countryId: contextValue.currentCountry.id,
  };
};
