import { Typography, Modal, Table } from "antd";
import { FC, useState } from "react";
import { useConfig } from "../../auth/components/ConfigContext";
import {
  FinanceAccount,
  FinanceAccountAudit,
  formatDate,
  getCommonExpandConfig,
  RELATIONSHIP_ITEM_PER_PAGE,
} from "../../common";
import ReactJson from "react-json-view";
import { RenderFinanceEntryType } from "./RenderFinanceEntryType";
import { RenderFinanceStatus } from "./RenderFinanceStatus";

export const FinanceAccounts: FC<{
  dataSource: FinanceAccount[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
}> = (props) => {
  const { dataSource, loading, onPageChange, total } = props;
  const [auditData, setAuditData] = useState<FinanceAccountAudit[]>([]);
  const [expandedRow, setExpandedRow] = useState<number>(0);
  const [metadata, setMetadata] = useState<Record<string, string> | null>(null);
  const { formatCurrency } = useConfig();
  const expandedRowRender = () => {
    return (
      <Table
        dataSource={auditData}
        pagination={false}
        size="small"
        scroll={{ x: true }}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Finance Account ID",
            dataIndex: "finance_account_id",
            key: "finance_account_id",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: FinanceAccountAudit["status"]) => (
              <RenderFinanceStatus value={value} />
            ),
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: FinanceAccountAudit["created_at"]) =>
              formatDate(value),
          },
          {
            title: "Attributes",
            dataIndex: "attributes",
            key: "attributes",
            render: (value: FinanceAccountAudit["attributes"]) => (
              <div
                onClick={() => setMetadata(value ? JSON.parse(value) : {})}
                style={{ cursor: "pointer" }}
              >
                <Typography.Text underline>View Attributes</Typography.Text>
              </div>
            ),
          },
        ]}
      />
    );
  };
  return (
    <>
      <Modal
        title="Metadata"
        footer={false}
        visible={!!metadata}
        destroyOnClose
        onCancel={() => setMetadata(null)}
        width="80%"
      >
        {metadata && <ReactJson src={metadata} />}
      </Modal>
      <Table
        dataSource={dataSource}
        loading={loading}
        size="small"
        scroll={{ x: true }}
        expandable={{
          ...getCommonExpandConfig<FinanceAccount>(),
          expandedRowRender,
          onExpand: (expanded, record) => {
            if (expanded) {
              setAuditData(record.finance_account_audit);
              setExpandedRow(record.id);
            } else {
              setAuditData([]);
              setExpandedRow(0);
            }
          },
          expandedRowKeys: [expandedRow],
        }}
        rowKey="id"
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Entry Type",
            dataIndex: "entry_type",
            key: "entry_type",
            render: (value: FinanceAccount["entry_type"]) => (
              <RenderFinanceEntryType value={value} />
            ),
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (value: FinanceAccount["amount"], record) => (
              <Typography.Text>
                {formatCurrency(value, undefined, record.currency)}
              </Typography.Text>
            ),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: FinanceAccount["status"]) => (
              <RenderFinanceStatus value={value} />
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
          },
          {
            title: "Finance Transaction ID",
            dataIndex: "finance_transaction_id",
            key: "finance_transaction_id",
          },
          {
            title: "Group Member Period ID",
            dataIndex: "group_member_period_id",
            key: "group_member_period_id",
          },
          {
            title: "Effective Plan At",
            dataIndex: "effective_plan_at",
            key: "effective_plan_at",
            render: (value: FinanceAccount["effective_plan_at"]) =>
              value ? formatDate(value) : "--",
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: FinanceAccount["created_at"]) => formatDate(value),
          },
          {
            title: "Notes",
            dataIndex: "description",
            key: "description",
          },
          {
            title: "Meta",
            dataIndex: "metadata",
            key: "metadata",
            render: (value: FinanceAccount["metadata"]) =>
              value ? (
                <div
                  onClick={() => setMetadata(value)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text underline>View Meta</Typography.Text>
                </div>
              ) : (
                "--"
              ),
          },
        ]}
        pagination={{
          total,
          pageSize: RELATIONSHIP_ITEM_PER_PAGE,
        }}
        onChange={(pagination) =>
          pagination.current && onPageChange(pagination.current)
        }
      />
    </>
  );
};
