import { Typography, Modal, Table, Tag } from "antd";
import { FC, useState } from "react";
import { useConfig } from "../../auth/components/ConfigContext";
import {
  FinanceTransactionAudit,
  FinanceTransactions as FinanceTransactionsType,
  formatDate,
  getCommonExpandConfig,
  RELATIONSHIP_ITEM_PER_PAGE,
} from "../../common";
import ReactJson from "react-json-view";

export const FinanceTransactions: FC<{
  dataSource: FinanceTransactionsType[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
}> = (props) => {
  const { dataSource, loading, onPageChange, total } = props;
  const [auditData, setAuditData] = useState<FinanceTransactionAudit[]>([]);
  const [expandedRow, setExpandedRow] = useState<number>(0);
  const [metadata, setMetadata] = useState<Record<string, string> | null>(null);
  const { formatCurrency } = useConfig();
  const expandedRowRender = () => {
    return (
      <Table
        dataSource={auditData}
        pagination={false}
        size="small"
        scroll={{ x: true }}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Finance Transaction ID",
            dataIndex: "finance_transaction_id",
            key: "finance_transaction_id",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: FinanceTransactionAudit["status"]) => (
              <Tag color="default">{value.toLocaleUpperCase()}</Tag>
            ),
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: FinanceTransactionAudit["created_at"]) =>
              formatDate(value),
          },
          {
            title: "Attributes",
            dataIndex: "attributes",
            key: "attributes",
            render: (value: FinanceTransactionAudit["attributes"]) => (
              <div
                onClick={() => setMetadata(value ? JSON.parse(value) : {})}
                style={{ cursor: "pointer" }}
              >
                <Typography.Text underline>View Attributes</Typography.Text>
              </div>
            ),
          },
        ]}
      />
    );
  };
  return (
    <>
      <Modal
        title="Metadata"
        footer={false}
        visible={!!metadata}
        destroyOnClose
        onCancel={() => setMetadata(null)}
        width="80%"
      >
        {metadata && <ReactJson src={metadata} />}
      </Modal>
      <Table
        dataSource={dataSource}
        loading={loading}
        size="small"
        scroll={{ x: true }}
        expandable={{
          ...getCommonExpandConfig<FinanceTransactionsType>(),
          expandedRowRender,
          onExpand: (expanded, record) => {
            if (expanded) {
              setAuditData(record.finance_transaction_audit);
              setExpandedRow(record.id);
            } else {
              setAuditData([]);
              setExpandedRow(0);
            }
          },
          expandedRowKeys: [expandedRow],
        }}
        rowKey="id"
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Payment Method ID",
            dataIndex: "user_payment_method_id",
            key: "user_payment_method_id",
          },
          {
            title: "Payment Mask",
            dataIndex: "mask",
            key: "mask",
          },
          {
            title: "Payment Type",
            dataIndex: "type",
            key: "type",
            render: (value: FinanceTransactionsType["type"]) => (
              <Tag color="default">{value.toLocaleUpperCase()}</Tag>
            ),
          },
          {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (value: FinanceTransactionsType["amount"], record) => (
              <Typography.Text>
                {formatCurrency(value, undefined, record.currency)}
              </Typography.Text>
            ),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: FinanceTransactionsType["status"]) => (
              <Tag color="default">{value.toLocaleUpperCase()}</Tag>
            ),
          },
          {
            title: "Payment Gateway",
            dataIndex: "paygw",
            key: "paygw",
          },
          {
            title: "Payment Gateway Type",
            dataIndex: "paygw_type",
            key: "paygw_type",
          },
          {
            title: "Payment Gateway ID",
            dataIndex: "paygw_id",
            key: "paygw_id",
          },
          {
            title: "Payment Gateway Status",
            dataIndex: "paygw_status",
            key: "paygw_status",
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
          },
          {
            title: "Finance Account Entry ID",
            dataIndex: "finance_account_entry_id",
            key: "finance_account_entry_id",
          },
          {
            title: "Object Type",
            dataIndex: "object_type",
            key: "object_type",
          },
          {
            title: "Object ID",
            dataIndex: "object_id",
            key: "object_id",
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: FinanceTransactionsType["created_at"]) =>
              formatDate(value),
          },
          {
            title: "Notes",
            dataIndex: "description",
            key: "description",
          },
          {
            title: "Meta",
            dataIndex: "metadata",
            key: "metadata",
            render: (value: FinanceTransactionsType["metadata"]) =>
              value ? (
                <div
                  onClick={() => setMetadata(value)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text underline>View Meta</Typography.Text>
                </div>
              ) : (
                "--"
              ),
          },
        ]}
        pagination={{
          total,
          pageSize: RELATIONSHIP_ITEM_PER_PAGE,
        }}
        onChange={(pagination) =>
          pagination.current && onPageChange(pagination.current)
        }
      />
    </>
  );
};
