import { FC, useState } from "react";
import { RELATIONSHIP_ITEM_PER_PAGE, USER_CACHE_KEYS } from "../../common";
import { useQuery } from "react-query";
import { getUserCommunications } from "../../common/api/users";
import { Communications } from "../../groups/components/Communications";

export const UserCommunications: FC<{ userId: number }> = (props) => {
  const { userId } = props;
  const [page, setPage] = useState(1);
  const { data, isLoading } = useQuery(
    [USER_CACHE_KEYS.COMMUNICATIONS(userId), page],
    () =>
      getUserCommunications(userId, {
        itemsPerPage: RELATIONSHIP_ITEM_PER_PAGE,
        page,
      }),
    {
      keepPreviousData: true,
    }
  );

  return (
    <Communications
      dataSource={data?.data.data.relation_data.data || []}
      loading={isLoading}
      onPageChange={setPage}
      total={data?.data.data.relation_data.total || 0}
    />
  );
};
