import { FC } from "react";
import { APIError, Group, GroupStatus, updateGroupStatus } from "../../common";
import { Select, message } from "antd";
import { useMutation } from "react-query";

export const EditStatus: FC<{ group: Group; onEdit?: () => void }> = (
  props
) => {
  const { group, onEdit } = props;
  const { mutate: update, status } = useMutation(updateGroupStatus, {
    onError: (e) => {
      const error = e as APIError;
      message.error(error.data?.errors.message);
    },
    onSuccess: () => {
      onEdit?.();
    },
  });
  const handleStatusChange = (value: GroupStatus) => {
    update({
      status: value,
      mask: group.mask,
    });
  };
  const STATUSES: Array<{ value: GroupStatus; label: string }> = [
    {
      value: "active",
      label: "Active",
    },
    {
      value: "finished",
      label: "Finished",
    },
    {
      value: "incident",
      label: "Incident",
    },
    {
      value: "validating",
      label: "Validating",
    },
  ];
  return (
    <Select
      style={{ width: 150 }}
      options={STATUSES}
      value={group.status}
      onChange={handleStatusChange}
      loading={status === "loading"}
    />
  );
};
