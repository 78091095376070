import { Button, Modal, notification } from "antd";
import { FC } from "react";
import {
  APIError,
  canUpdateUser,
  revokeTokens as revokeTokensAPI,
  UserAllowedActions,
} from "../../common";
import { CloseOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

export const RevokeTokensButton: FC<{
  userId: number;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  allowedActions: UserAllowedActions[];
}> = (props) => {
  const { userId, onSuccess, onError, allowedActions } = props;
  const { mutate: revokeTokens, isLoading } = useMutation(revokeTokensAPI);
  const handleRevoke = () => {
    Modal.confirm({
      title: "Are you sure, you want to revoke all login tokens for the user?",
      content: "NOTE: The user will be logged out of the platform",
      onOk: () => {
        revokeTokens(userId, {
          onSuccess: () => {
            notification.success({
              message: "User logged out from all places",
              description: "All user access tokens have been revoked!",
            });
            onSuccess?.();
          },
          onError: (e) => {
            const error = e as APIError;
            notification.error({
              message: error.data?.errors.message,
              description: error.data?.errors.errorCode,
            });
            onError?.(e);
          },
        });
      },
    });
  };

  if (!canUpdateUser(allowedActions)) {
    return null;
  }

  return (
    <Button
      icon={<CloseOutlined />}
      danger
      onClick={handleRevoke}
      loading={isLoading}
    >
      Logout User
    </Button>
  );
};
