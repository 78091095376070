import { ButtonProps, Button, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import { FC, useState } from "react";
import {
  canAddCredit,
  canRemoveCredit,
  toCapital,
  UserAllowedActions,
} from "../../common";
import { DollarCircleOutlined } from "@ant-design/icons";
import { useUserDetails } from "./UserDetailsContext";
import { UpdateBalanceForm } from "./UpdateBalanceForm";

export const UpdateBalanceButton: FC<
  ButtonProps & { onSuccess?: () => void; onError?: (e: any) => void }
> = (props) => {
  const { disabled, onSuccess, onError, ...buttonProps } = props;
  const { user, allowedActions, refetch } = useUserDetails();
  const [mode, setMode] = useState<"credit" | "debit" | null>(null);
  const handleFinish = () => {
    refetch();
    setMode(null);
  };

  if (!canAddCredit(allowedActions) && !canRemoveCredit(allowedActions)) {
    return null;
  }

  const actions = getAllowedFinanceActions(allowedActions);
  return (
    <>
      <Modal
        visible={!!mode}
        destroyOnClose
        onCancel={() => setMode(null)}
        footer={false}
        title={`${toCapital(mode || "")} Money`}
      >
        {mode ? (
          <UpdateBalanceForm
            onFinish={handleFinish}
            userId={user?.id!}
            mode={mode}
          />
        ) : null}
      </Modal>
      <Space direction="vertical" align="end">
        {actions.includes("credit") ? (
          <Button
            type="primary"
            {...buttonProps}
            disabled={disabled}
            onClick={() => setMode("credit")}
            icon={<DollarCircleOutlined />}
          >
            Add Money
          </Button>
        ) : null}
        {actions.includes("debit") ? (
          <Button
            type="default"
            {...buttonProps}
            disabled={disabled}
            onClick={() => setMode("debit")}
            icon={<DollarCircleOutlined />}
          >
            Remove Money
          </Button>
        ) : null}
      </Space>
    </>
  );
};
function getAllowedFinanceActions(allowedActions: UserAllowedActions[]) {
  let actions: ("credit" | "debit")[] = [];

  if (canAddCredit(allowedActions)) {
    actions = actions.concat(["credit"]);
  }

  if (canRemoveCredit(allowedActions)) {
    actions = actions.concat(["debit"]);
  }

  return actions;
}
