import { FC, useState } from "react";
import { RELATIONSHIP_ITEM_PER_PAGE, USER_CACHE_KEYS } from "../../common";
import { Members } from "../../groups/components/Members";
import { useQuery } from "react-query";
import { getUserGroups } from "../../common/api/users";
import { useUserDetails } from "./UserDetailsContext";

export const UserGroups: FC<{ userId: number }> = (props) => {
  const { userId } = props;
  const [page, setPage] = useState(1);
  const { refetch: refetchUserDetails } = useUserDetails();
  const {
    data,
    isLoading,
    refetch: refetchMembers,
  } = useQuery(
    [USER_CACHE_KEYS.MEMBERS(userId), page],
    () =>
      getUserGroups(userId, {
        itemsPerPage: RELATIONSHIP_ITEM_PER_PAGE,
        page,
      }),
    {
      keepPreviousData: true,
    }
  );

  const handleActionsSuccess = () => {
    refetchUserDetails();
    refetchMembers();
  };

  return (
    <Members
      dataSource={data?.data.data.relation_data.data || []}
      loading={isLoading}
      onPageChange={setPage}
      total={data?.data.data.relation_data.total || 0}
      onActionSuccess={handleActionsSuccess}
    />
  );
};
