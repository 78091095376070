import { Button, Modal, Input, notification } from "antd";
import { FC, useState } from "react";
import { APIError } from "..";

export const InternalNotes: FC<{
  buttonText: string;
  defaultValue?: string;
  onSuccess: (notes: string) => Promise<any>;
  loading: boolean;
}> = (props) => {
  const { buttonText, defaultValue, onSuccess, loading } = props;
  const [visible, setVisible] = useState(false);
  const [notes, setNotes] = useState(defaultValue || "");
  return (
    <>
      <Modal
        visible={visible}
        title={buttonText}
        maskClosable={false}
        okButtonProps={{
          loading,
        }}
        onCancel={() => {
          setVisible(false);
          setNotes(defaultValue || "");
        }}
        onOk={() =>
          onSuccess(notes)
            .then(() => {
              setVisible(false);
              notification.success({
                message: "Internal notes updated successfully!",
              });
            })
            .catch((e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.validator?.internal_notes,
              });
            })
        }
        destroyOnClose
      >
        <Input.TextArea
          rows={10}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Modal>
      <Button onClick={() => setVisible(true)} type="primary">
        {buttonText}
      </Button>
    </>
  );
};
