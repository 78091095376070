import { useLocation } from "@reach/router";
import {
  Typography,
  Row,
  Col,
  Space,
  Divider,
  Image,
  Descriptions,
} from "antd";
import { FC } from "react";
import { useConfig } from "../../auth/components/ConfigContext";
import {
  canUpdateUser,
  formatDate,
  makeBooleanReadable,
  ROUTES,
  updateUser as updateUserAPI,
  UserAllowedActions,
  UserDetails as UserDetailsType,
} from "../../common";
import { RenderEmail } from "./RenderEmail";
import { ManagePermissionsButton } from "./ManagePermissionsButton";
import { UserUpdateButton } from "./UserUpdateButton";
import { UpdateBalanceButton } from "./UpdateBalanceButton";
import { EntityTags } from "../../common/components/EntityTags";
import { RevokeTokensButton } from "./RevokeTokensButton";
import { useMutation } from "react-query";
import { InternalNotes } from "../../common/components/InternalNotes";

export const UserInfo: FC<{
  user: UserDetailsType;
  refetch: () => void;
  allowedActions: UserAllowedActions[];
}> = (props) => {
  const { user, refetch, allowedActions } = props;
  const { formatCurrency } = useConfig();
  const location = useLocation();

  const { mutate: updateUser, isLoading: loadingUserUpdate } =
    useMutation(updateUserAPI);

  const updateInternalNotes = (notes: string) => {
    return new Promise((resolve, reject) => {
      updateUser(
        {
          userId: user.id,
          internalNotes: notes,
        },
        {
          onSuccess: () => {
            refetch();
            resolve(null);
          },
          onError: (e) => {
            reject(e);
          },
        }
      );
    });
  };
  return (
    <Space direction="vertical" size="middle">
      <Row justify="space-between">
        <Col>
          <Space
            align="center"
            split={
              <Divider
                type="vertical"
                style={{ borderWidth: "2px", borderColor: "gray" }}
              />
            }
          >
            <Typography.Title level={4} style={{ margin: 0 }} copyable>
              {user.firstName}
              {user.lastName ? ` ${user.lastName}` : ""}
            </Typography.Title>
            <RenderEmail
              level={4}
              email={user.email}
              type="title"
              style={{ margin: 0 }}
            />
            <Typography.Title
              level={4}
              style={{ margin: 0 }}
              copyable={{
                text:
                  location.origin +
                  ROUTES.getUserDetailsRoute(user.id.toString()),
                tooltips: "Copy User URL",
              }}
            >
              ID: {user.id}
            </Typography.Title>
          </Space>
        </Col>
        <Col>
          <Space size="small">
            <RevokeTokensButton
              userId={user.id}
              allowedActions={allowedActions}
            />
            <UserUpdateButton size="large" type="default" />
            <ManagePermissionsButton size="large" />
          </Space>
        </Col>
        <Col span={23}>
          <EntityTags
            tags={user.tags}
            refetch={refetch}
            entityId={user.id}
            entityType="user"
            canUpdate={canUpdateUser(allowedActions)}
          />
        </Col>
      </Row>

      {/* User Image */}
      <Row justify="space-between" align="top">
        <Col span={20}>
          <Descriptions>
            <Descriptions.Item label="Language">
              {user.language}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {user.phoneNumber
                ? `${user.phoneCountry} ${user.phoneNumber}`
                : "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Verified">
              {makeBooleanReadable(user.isPhoneVerified)}
            </Descriptions.Item>
            <Descriptions.Item label="Email Verified">
              {makeBooleanReadable(user.isEmailVerified)}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              {formatDate(user.createdAt)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col>
          {user.imageAvatar && (
            <Image
              preview
              width="80px"
              style={{ borderRadius: "50%", border: "1px solid gray" }}
              src={user.imageAvatar}
            />
          )}
        </Col>
      </Row>

      {/* Internal Notes */}
      <Row justify="space-between" align="middle">
        <Col span={20}>
          <Descriptions title="Internal Notes" column={1}>
            <Typography.Paragraph>
              {user.internalNotes || "-"}
            </Typography.Paragraph>
          </Descriptions>
        </Col>
        {canUpdateUser(allowedActions) && (
          <Col>
            <InternalNotes
              buttonText="Update Notes"
              onSuccess={updateInternalNotes}
              defaultValue={user.internalNotes}
              loading={loadingUserUpdate}
            />
          </Col>
        )}
      </Row>

      {/* Finance */}
      <Row justify="space-between">
        <Col span={20}>
          <Descriptions title="Balances" column={1}>
            <Descriptions.Item label="Balance Available">
              {formatCurrency(user.balanceAvailable, user.countryId)}
            </Descriptions.Item>
            <Descriptions.Item label="Balance On Hold">
              {formatCurrency(user.balanceOnHold, user.countryId)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col>
          <UpdateBalanceButton />
        </Col>
      </Row>
    </Space>
  );
};
