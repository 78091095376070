import { getAuthHeader, pulpoAPI } from "../fetch";
import { Communication, CredentialsTypes, GroupAction, GroupActions, GroupCredentials, GroupDetails, UserGroupRelation, GroupStatus as GroupStatusTypes } from "../types";
import { b64DecodeUnicode } from "../utils";

// NOTE: Identifier could be group mask or group id
export const getGroupDetails = async (identifier: string) => {
  const response = await pulpoAPI({
    url: `/v1/group/${identifier}/`,
    method: "GET",
    headers: {
      ...getAuthHeader(),
    },
  })
  const { group } = response.data.data
  const groupDetails: GroupDetails = {
    id: group.id,
    countryId: group.country_id,
    mask: group.mask,
    status: group.status,
    public: group.public,
    allowEnrollments: group.allow_enrollments,
    periodLength: group.period_length,
    slotNumTotal: group.slot_num_total,
    slotNumAvailable: group.slot_num_available,
    slotPrice: group.slot_price,
    slotFee: group.slot_fee,
    slotPriceUpdateFromPlatform: group.slot_price_update_from_platform,
    tags: group.tags || [],
    internalNotes: group.internal_notes || "",
    owner: {
      userId: group.owner.user_id,
      firstName: group.owner.first_name,
      email: group.owner.email,
    },
    platform: {
      platformId: group.platform.platform_id,
      name: group.platform.name,
      infoWebURL: group.platform.info_url_web,
      type: group.platform.type,
    }
  }
  const allowedActions: GroupActions[] = response.data.data.allowed_actions;
  return {
    group: groupDetails,
    allowedActions
  }
}

type UpdateSlots = {
  slots: number;
  mask: string;
};
export const updateSlots = async (group: UpdateSlots) => {
  const response = await pulpoAPI<{
    group: {
      mask: string;
    };
  }>({
    method: "PUT",
    url: `/v1/group/${group.mask}/slots`,
    data: {
      slot_num_total: group.slots,
    },
    headers: {
      ...getAuthHeader(),
    },
  });

  return response.data.data.group.mask;
};

type GroupStatus = {
  status: GroupStatusTypes;
  mask: string;
};
export const updateGroupStatus = async (group: GroupStatus) => {
  const response = await pulpoAPI<{
    group: {
      mask: string;
    };
  }>({
    method: "PUT",
    url: `/v1/group/${group.mask}/status`,
    data: {
      new_status: group.status,
    },
    headers: {
      ...getAuthHeader(),
    },
  });

  return response.data.data.group.mask;
};

type UpdateFee = {
  fee: number;
  mask: string;
};
export const updateFee = async (group: UpdateFee) => {
  const response = await pulpoAPI<{
    group: {
      mask: string;
    };
  }>({
    method: "PUT",
    url: `/v1/group/${group.mask}/fee`,
    data: {
      slot_fee: group.fee,
    },
    headers: {
      ...getAuthHeader(),
    },
  });

  return response.data.data.group.mask;
};

type UpdateCredentials = {
  credentialsType: CredentialsTypes;
  secret1: string;
  secret2?: string;
  addressText?: string;
  placesId?: string;
  mask: string;
};
export const updateCredentials = async (group: UpdateCredentials) => {
  return await pulpoAPI<{
    group: {
      mask: string;
    };
  }>({
    method: "PUT",
    url: `/v1/group/${group.mask}/credentials`,
    data: {
      credentials_type: group.credentialsType,
      secret_1: group.secret1,
      secret_2: group.secret2,
      address_text: group.addressText,
      address_places_id: group.placesId
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const getCredentials = async (groupMask: string) => {
  const response = await pulpoAPI({
    method: "GET",
    url: `/v1/group/${groupMask}/credentials`,
    headers: {
      ...getAuthHeader(),
    },
  });

  const modelledResponse: GroupCredentials = {
    credentialsType: response.data.data.credentials_type,
    secret1: atob(response.data.data.secret_1),
    secret2: response.data.data.secret_2 ? b64DecodeUnicode(response.data.data.secret_2) : response.data.data.secret_2,
    addressText: response.data.data.address_text ? b64DecodeUnicode(response.data.data.address_text) : response.data.data.address_text,
    placesId: response.data.data.address_places_id ? b64DecodeUnicode(response.data.data.address_places_id) : response.data.data.address_places_id,
  };

  return modelledResponse;
};

type GroupFinishPayload = {
  mask: string;
  trigger: "afe_staff_action",
  reason1?: string;
  reason2?: string;
}
export const finishGroup = (data: GroupFinishPayload) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/finish`,
    data: {
      trigger: data.trigger,
      ...(data.reason1 ? { reason_1: data.reason1 } : undefined),
      ...(data.reason2 ? { reason_2: data.reason2 } : undefined),
    },
    headers: {
      ...getAuthHeader(),
    },
  })
}

export const getGroupCommunications = (identifier: string, { itemsPerPage, page }: { itemsPerPage: number, page: number }) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: Communication[]
      total: number;
    }
  }>({
    url: `/v1/group/${identifier}/relation/communication`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  })
}


export const getGroupMembers = (identifier: string, { itemsPerPage, page }: { itemsPerPage: number, page: number }) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: UserGroupRelation[]
      total: number;
    }
  }>({
    url: `/v1/group/${identifier}/relation/group_member`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  })
}


export const getGroupActions = (identifier: string, { itemsPerPage, page }: { itemsPerPage: number, page: number }) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: GroupAction[]
      total: number;
    }
  }>({
    url: `/v1/group/${identifier}/relation/group_action`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  })
}

type ChangeRemoveMemberPayload = {
  trigger: "afe_staff_action",
  changeGroup: boolean;
  forceLeaving: boolean;
  stopIfNoAvailableGroup: boolean;
  mask: string;
  groupMemberId: number;
  reason?: string;
}
export const changeRemoveMember = async (data: ChangeRemoveMemberPayload) => {
  return await pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/member/${data.groupMemberId}/remove-change`,
    data: {
      trigger: data.trigger,
      change_group: data.changeGroup,
      stop_if_no_available_group: data.stopIfNoAvailableGroup,
      force_leaving: data.forceLeaving,
      ...(data.reason ? { reason_1: data.reason } : undefined),
    },
    headers: {
      ...getAuthHeader(),
    },
  })
}

export const updateGroupTags = async ({ tags, mask }: { tags: string[], mask: string }) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${mask}/tags`,
    data: {
      tags,
    },
    headers: {
      ...getAuthHeader(),
    },
  })
}

export const initiateValidationRequest = async (mask: string) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${mask}/request-automatic-validation`,
    headers: {
      ...getAuthHeader(),
    },
  })
}

export const reactivateMember = async (data: { mask: string, memberId: number }) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/member/${data.memberId}/reactivate`,
    headers: {
      ...getAuthHeader()
    }
  })
}

export const forceChargeMember = async (data: { mask: string, memberId: number }) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/member/${data.memberId}/manual-charge-unpaid`,
    headers: {
      ...getAuthHeader()
    }
  })
}

export const updateInternalData = (data: { tags?: string[], internalNotes?: string, mask: string; }) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/internal-data`,
    headers: {
      ...getAuthHeader()
    },
    data: {
      tags: data.tags,
      internal_notes: data.internalNotes
    }
  })
}

type AcceptRejectMemberData = {
  mask: string;
  memberId: number;
}

export const acceptMember = (data: AcceptRejectMemberData) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/member/${data.memberId}/booked/confirm`,
    headers: {
      ...getAuthHeader(),
    },
  })
}

export const rejectMember = (data: AcceptRejectMemberData) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/group/${data.mask}/member/${data.memberId}/booked/cancel`,
    headers: {
      ...getAuthHeader(),
    },
  })
}