import { Typography, Table, Tag, Modal } from "antd";
import { FC, useState } from "react";
import {
  formatDate,
  RELATIONSHIP_ITEM_PER_PAGE,
  GroupAction,
  GroupActionAudit,
  getCommonExpandConfig,
} from "../../common";
import { UserId } from "./UserId";
import { GroupId } from "./GroupId";
import ReactJson from "react-json-view";

export const GroupActions: FC<{
  dataSource: GroupAction[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
}> = (props) => {
  const { dataSource, loading, onPageChange, total } = props;
  const [auditData, setAuditData] = useState<GroupActionAudit[]>([]);
  const [expandedRow, setExpandedRow] = useState<number>(0);
  const [metadata, setMetadata] = useState<Record<string, string> | null>(null);
  const expandedRowRender = () => {
    return (
      <Table
        dataSource={auditData}
        pagination={false}
        size="small"
        scroll={{ x: true }}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: GroupActionAudit["status"]) => (
              <Tag color="default">{value.toUpperCase()}</Tag>
            ),
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: GroupActionAudit["created_at"]) =>
              formatDate(value),
          },
          {
            title: "Attributes",
            dataIndex: "attributes",
            key: "attributes",
            render: (value: GroupActionAudit["attributes"]) => (
              <div
                onClick={() => setMetadata(value ? JSON.parse(value) : {})}
                style={{ cursor: "pointer" }}
              >
                <Typography.Text underline>View Attributes</Typography.Text>
              </div>
            ),
          },
        ]}
      />
    );
  };
  return (
    <>
      <Modal
        title="Metadata"
        footer={false}
        visible={!!metadata}
        destroyOnClose
        onCancel={() => setMetadata(null)}
        width="80%"
      >
        {metadata && <ReactJson src={metadata} />}
      </Modal>
      <Table
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: true }}
        size="small"
        expandable={{
          ...getCommonExpandConfig<GroupAction>(),
          expandedRowRender,
          onExpand: (expanded, record) => {
            if (expanded) {
              setAuditData(record.group_action_audit);
              setExpandedRow(record.id);
            } else {
              setAuditData([]);
              setExpandedRow(0);
            }
          },
          expandedRowKeys: [expandedRow],
        }}
        rowKey="id"
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "User",
            dataIndex: "user",
            key: "user",
            render: (value: GroupAction["user"]) =>
              value ? (
                <UserId
                  value={value.id}
                  lastName={value.last_name || undefined}
                  firstName={value.first_name}
                />
              ) : (
                "--"
              ),
          },
          {
            title: "Group",
            dataIndex: "group",
            key: "group",
            render: (value: GroupAction["group"]) => (
              <GroupId value={value.id} mask={value.mask} />
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: GroupAction["status"]) => (
              <Tag color="default">{value.toUpperCase()}</Tag>
            ),
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: GroupAction["created_at"]) => formatDate(value),
          },
          {
            title: "Meta",
            dataIndex: "metadata",
            key: "metadata",
            render: (value: GroupAction["metadata"]) => (
              <div
                onClick={() => setMetadata(value)}
                style={{ cursor: "pointer" }}
              >
                <Typography.Text underline>View Meta</Typography.Text>
              </div>
            ),
          },
        ]}
        pagination={{
          total,
          pageSize: RELATIONSHIP_ITEM_PER_PAGE,
        }}
        onChange={(pagination) =>
          pagination.current && onPageChange(pagination.current)
        }
      />
    </>
  );
};
