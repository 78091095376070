import { GroupActions, GroupDetails, UserAllowedActions } from "./types";

export const canFinishGroup = (group: GroupDetails, actions: GroupActions[]) => {
  return group.status !== "finished" && actions.includes("group_finalization")
}

export const canActivateGroupStatus = (group: GroupDetails, actions: GroupActions[]) => {
  return ["validating", "incident"].includes(group.status) && actions.includes("group_status_update_active")
}

export const canSeeCredentials = (actions: GroupActions[]) => {
  return actions.includes("group_credentials_view")
}

export const canEditCredentials = (actions: GroupActions[]) => {
  return actions.includes("group_credentials_update")
}

export const canSeePermissions = (actions: UserAllowedActions[]) => {
  return actions.includes("user_permission_list")
}

export const canEditPermissions = (actions: UserAllowedActions[]) => {
  return actions.includes("user_permission_update")
}

export const canUpdateUser = (actions: UserAllowedActions[]) => {
  return actions.includes("user_update");
}

export const canAddCredit = (actions: UserAllowedActions[]) => {
  return actions.includes("user_credit_add");
}

export const canRemoveCredit = (actions: UserAllowedActions[]) => {
  return actions.includes("user_credit_remove");
}

export const canUpdateGroupTags = (actions: GroupActions[]) => {
  return actions.includes("group_update_tags");
}

export const canMakeGroupIncident = (actions: GroupActions[]) => {
  return actions.includes("group_status_update_incident");
}
export const canInitiateValidationRequest = (actions: GroupActions[]) => {
  return actions.includes("group_validation_request");
}