import { getAuthHeader, pulpoAPI } from "../fetch";
import {
  Communication,
  UserGroupRelation,
  UserAllowedActions,
  UserDetails,
  GroupAction,
  FinanceAccount,
  FinanceTransactions,
  GroupsAsOwner,
  PaymentMethods,
} from "../types";

type CreateUser = {
  country_id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: "staff";
  language: string;
};
export const createUser = async (user: CreateUser) => {
  const response = await pulpoAPI<{
    user: {
      id: number;
    };
  }>({
    method: "POST",
    url: "/v1/user",
    data: user,
    headers: {
      ...getAuthHeader(),
    },
  });
  return response.data.data;
};

type UpdateUser = {
  firstName?: string;
  lastName?: string;
  language?: string;
  tags?: string[];
  password?: string;
  email?: string;
  userId: number;
  phoneCountry?: string;
  phoneNumber?: string;
  internalNotes?: string;
};
export const updateUser = async (user: UpdateUser) => {
  const response = await pulpoAPI({
    method: "PATCH",
    url: `/v1/user/${user.userId}/profile`,
    data: {
      first_name: user.firstName,
      last_name: user.lastName,
      language: user.language,
      tags: user.tags,
      email: user.email,
      password: user.password,
      phone_country: user.phoneCountry,
      phone_number: user.phoneNumber,
      internal_notes: user.internalNotes
    },
    headers: {
      ...getAuthHeader(),
    },
  });
  return response.data.data;
};

type UpdatePermission = {
  permission: string;
  action: "grant" | "revoke";
  userId: number;
};
export const updateUserPermissions = async (permission: UpdatePermission) => {
  const response = await pulpoAPI({
    method: "PUT",
    url: `/v1/user/${permission.userId}/permission`,
    data: permission,
    headers: {
      ...getAuthHeader(),
    },
  });
  return response.data.data;
};

export const getUserPermissions = async (userId: number) => {
  const response = await pulpoAPI<{
    permissions: Record<string, boolean>[];
  }>({
    method: "GET",
    url: `/v1/user/${userId}/permissions`,
    headers: {
      ...getAuthHeader(),
    },
  });

  return response.data.data.permissions.reduce(
    (acc, permission) => ({
      ...acc,
      ...permission,
    }),
    {}
  );
};

export const getUserDetails = async (userId: number) => {
  const response = await pulpoAPI({
    method: "GET",
    url: `/v1/user/${userId}`,
    headers: {
      ...getAuthHeader(),
    },
  });

  const { user, allowed_actions: allowedActions } = response.data.data;
  const userDetails: UserDetails = {
    id: user.id,
    countryId: user.country_id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    language: user.language,
    phoneCountry: user.phone_country,
    phoneNumber: user.phone_number,
    imageAvatar: user.image_avatar,
    isPhoneVerified: user.is_phone_verified,
    isEmailVerified: user.is_email_verified,
    balanceAvailable: user.balance_available,
    balanceOnHold: user.balance_onhold,
    tags: user.tags || [],
    internalNotes: user.internal_notes || "",
    createdAt: user.created_at
  };

  return {
    userDetails,
    allowedActions: allowedActions as UserAllowedActions[],
  };
};

type CreditPayload = {
  entryType: "credit" | "debit";
  amount: number;
  onHoldUntil?: string;
  description?: string;
  userId: number;
};
export const addRemoveCredit = (data: CreditPayload) => {
  return pulpoAPI({
    method: "POST",
    url: `/v1/user/${data.userId}/add-remove-credit`,
    headers: {
      ...getAuthHeader(),
    },
    data: {
      entry_type: data.entryType,
      amount: data.amount,
      on_hold_until: data.onHoldUntil,
      description: data.description,
    },
  });
};

export const getUserCommunications = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: Communication[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/communication`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const getUserGroups = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: UserGroupRelation[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/group_member`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const getUserGroupActions = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: GroupAction[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/group_action`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const getFinanceAccounts = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: FinanceAccount[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/finance_account`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};
export const getFinanceTransactions = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: FinanceTransactions[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/finance_transaction`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const getGroupsAsOwner = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: GroupsAsOwner[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/owner`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const getUserPaymentMethods = (
  userId: number,
  { itemsPerPage, page }: { itemsPerPage: number; page: number }
) => {
  return pulpoAPI<{
    relation_data: {
      current_page: number;
      data: PaymentMethods[];
      total: number;
    };
  }>({
    url: `/v1/user/${userId}/relation/user_payment_method`,
    method: "GET",
    params: {
      items_per_page: itemsPerPage,
      page,
    },
    headers: {
      ...getAuthHeader(),
    },
  });
};

export const revokeTokens = (userId: number) => {
  return pulpoAPI({
    method: "PUT",
    url: `/v1/user/${userId}/revoke-tokens`,
    headers: {
      ...getAuthHeader(),
    },
  })
}

type BulkTaggingData = {
  users_ids: string;
  tag: string;
}
export const bulkTagging = async (data: BulkTaggingData) => {
  return pulpoAPI({
    method: "POST",
    url: `/v1/users/massive-tag-assignation`,
    data,
    headers: {
      ...getAuthHeader(),
    },
  })
}