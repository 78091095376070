import { FC, useState } from "react";
import { APIError, Group, updateSlots } from "../../common";
import { Button, Space, Typography, InputNumber, Form, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

export const EditSlots: FC<{ group: Group; onEdit?: () => void }> = (props) => {
  const { group, onEdit } = props;
  const [mode, setMode] = useState<"edit" | "view">("view");
  const [form] = Form.useForm<{ slots: number }>();

  const { mutate: update, status } = useMutation(updateSlots, {
    onError: (e) => {
      const error = e as APIError;
      message.error(error.data?.errors.message);
    },
    onSuccess: () => {
      onEdit?.();
      setMode("view");
    },
  });
  const handleFinish = (values: { slots: number }) => {
    update({
      slots: values.slots,
      mask: group.mask,
    });
  };
  if (mode === "edit") {
    return (
      <Form form={form} onFinish={handleFinish}>
        <Space direction="vertical" size="small">
          <Form.Item name="slots" initialValue={group.slotsLeft} noStyle>
            <InputNumber placeholder="Slots" />
          </Form.Item>
          <Form.Item noStyle>
            <Space
              direction="horizontal"
              size="small"
              style={{ marginLeft: -7 }}
            >
              <Button
                loading={status === "loading"}
                type="link"
                htmlType="submit"
                size="small"
              >
                Save
              </Button>
              <Button
                size="small"
                type="link"
                htmlType="reset"
                onClick={() => setMode("view")}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
    );
  }

  if (mode === "view") {
    return (
      <Space direction="horizontal" size="small">
        <Typography.Text>{group.slotsLeft}</Typography.Text>
        <EditOutlined onClick={() => setMode("edit")} />
      </Space>
    );
  }

  // Ideally this should never happened!
  return null;
};
