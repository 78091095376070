import { ExpandableConfig } from "antd/es/table/interface";
import { MinusSquareTwoTone, PlusSquareTwoTone } from "@ant-design/icons";

export function getCommonExpandConfig<RecordType>(): Partial<
  ExpandableConfig<RecordType>
> {
  return {
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <MinusSquareTwoTone
          style={{ fontSize: 18 }}
          onClick={(e) => onExpand(record, e)}
        />
      ) : (
        <PlusSquareTwoTone
          style={{ fontSize: 18 }}
          onClick={(e) => onExpand(record, e)}
        />
      ),
  };
}
