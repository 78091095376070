import { Button, Tooltip, Modal, notification } from "antd";
import { FC } from "react";
import {
  APIError,
  forceChargeMember as forceChargeMemberAPI,
} from "../../common";
import { EuroCircleFilled } from "@ant-design/icons";
import { useMutation } from "react-query";

export const ForceChargeButton: FC<{
  memberId: number;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  groupMask: string;
}> = (props) => {
  const { memberId, onSuccess, onError, groupMask } = props;
  const { mutate: forceChargeMember, isLoading } =
    useMutation(forceChargeMemberAPI);
  const handleForceCharge = () => {
    Modal.confirm({
      title: "Are you sure, you want to manually force charge the member?",
      content:
        "NOTE: Money will be deducted automatically from the user's payment method",
      onOk: () => {
        forceChargeMember(
          {
            mask: groupMask,
            memberId,
          },
          {
            onSuccess: () => {
              notification.success({
                message: "Manual force charge request successful",
              });
              onSuccess?.();
            },
            onError: (e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.errorCode,
              });
              onError?.(e);
            },
          }
        );
      },
    });
  };
  return (
    <Tooltip title="Manual Force Charge">
      <Button
        icon={<EuroCircleFilled />}
        shape="circle"
        onClick={handleForceCharge}
        loading={isLoading}
      />
    </Tooltip>
  );
};
