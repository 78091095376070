import { RouteComponentProps, useNavigate } from "@reach/router";
import { FC } from "react";
import { Form, Input, Button, message } from "antd";
import { useMutation } from "react-query";
import { APIError, COLORED_LOGO, login as loginAPI } from "../../common";
import { useUser } from "./UserContext";
import * as Sentry from "@sentry/react";

export const Login: FC<RouteComponentProps> = (props) => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const { mutate: login, status } = useMutation(loginAPI, {
    onSuccess: (response) => {
      message.success("Authentication successful!");
      Sentry.setUser({
        id: String(response.user.id),
        username: response.user.firstName,
        email: response.user.email,
      });
      setUser({ ...response.user, token: response.token });
      // Navigating to "/" to let the router decide
      // which should be the first route after login
      navigate("/");
    },
    onError: (error: APIError) => {
      message.error(error.data?.errors.message);
    },
  });
  return (
    <Form
      layout="vertical"
      style={{
        margin: "auto",
        paddingTop: "15vh",
        maxWidth: "400px",
        textAlign: "center",
      }}
      name="login"
      onFinish={login}
    >
      <img src={COLORED_LOGO} width="200" alt="logo" />
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input size="large" type="email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password size="large" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={status === "loading"}
          type="primary"
          size="large"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
