import { createContext, FC, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import {
  ContextNotFoundError,
  getGroupDetails,
  GroupActions,
  GroupDetails as GroupDetailsType,
  // GROUP_CACHE_KEYS,
} from "../../common";
// import { queryClient } from "../../queryUtils";

type GroupContextType = {
  group: GroupDetailsType | undefined;
  allowedActions: GroupActions[];
  isLoading: boolean;
  error: any;
  refetch: () => void;
};

const GroupContext = createContext<GroupContextType | undefined>(undefined);

export const GroupProvider: FC<{ identifier: string }> = (props) => {
  const { identifier } = props;
  const { data, isLoading, error, refetch } = useQuery(
    `group-${identifier}`,
    () => getGroupDetails(identifier)
  );

  const contextValue: GroupContextType = useMemo(
    () => ({
      isLoading,
      error,
      group: data?.group,
      allowedActions: data?.allowedActions || [],
      refetch,
    }),
    [data?.allowedActions, data?.group, error, isLoading, refetch]
  );

  return (
    <GroupContext.Provider value={contextValue}>
      {props.children}
    </GroupContext.Provider>
  );
};

export const useGroup = () => {
  const contextValue = useContext(GroupContext);

  if (contextValue === undefined) {
    throw new ContextNotFoundError("GroupContext");
  }

  return contextValue;
};
