import { Typography } from "antd";
import { CSSProperties, FC } from "react";
import { UnhandledError } from "../../common";

export const RenderEmail: FC<{
  email: string;
  type?: "title" | "text";
  level?: 1 | 2 | 3 | 4 | 5;
  style?: CSSProperties;
}> = (props) => {
  const { email, type = "text", level, style } = props;

  const textNode = (function () {
    switch (type) {
      case "text":
        return (
          <Typography.Text copyable style={style}>
            {email}
          </Typography.Text>
        );
      case "title":
        return (
          <Typography.Title style={style} copyable level={level}>
            {email}
          </Typography.Title>
        );
      default:
        throw new UnhandledError(type, `RenderEmail`);
    }
  })();
  return <a href={`mailto:${email}`}>{textNode}</a>;
};
