import { FC } from "react";
import { Credentials } from "../../common";
import { Button, Form, Input } from "antd";

type FormData = {
  link: string;
  address: string;
};
export const LinkAddressForm: FC<{
  loading: boolean;
  onSubmit: (credentials: Credentials) => void;
  defaultValues: Partial<Credentials>;
}> = (props) => {
  const [form] = Form.useForm<FormData>();
  const { loading, defaultValues } = props;
  const handleFinish = (data: FormData) => {
    props.onSubmit({
      secret1: data.link,
      addressText: data.address,
      placesId: defaultValues.placesId,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      validateMessages={{
        required: "This is required",
      }}
      onFinish={handleFinish}
      initialValues={{
        address: defaultValues.addressText,
        link: defaultValues.secret1,
      }}
    >
      <Form.Item
        name="link"
        label="Link"
        required
        rules={[
          { required: true },
          { type: "url", message: "Enter a valid url" },
        ]}
      >
        <Input type="url" placeholder="Link" />
      </Form.Item>

      <Form.Item
        name="address"
        label="Address"
        required
        rules={[{ required: true }]}
      >
        <Input placeholder="Address" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
