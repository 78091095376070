import {
  Button,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import {
  canEditCredentials,
  canSeeCredentials,
  CredentialsTypes,
  getCredentials,
  GroupCredentials,
  UnhandledError,
} from "../../common";
import { useGroup } from "./GroupContext";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { EditCredentials } from "./EditCredentials";

const KEY_LABEL_MAPPER: Record<
  CredentialsTypes,
  { secret1: string; secret2?: string }
> = {
  email_pwd: {
    secret1: "Email",
    secret2: "Password",
  },
  link_address: {
    secret1: "Link",
  },
  user_pwd: {
    secret1: "Username",
    secret2: "Password",
  },
  phone_pwd: {
    secret1: "Phone Number",
  },
};

export const Credentials: FC<{ identifier: string }> = (props) => {
  const { identifier } = props;
  const { allowedActions, refetch, group } = useGroup();
  const [mode, setMode] = useState<"edit" | "view">("view");
  const toggleMode = () => {
    switch (mode) {
      case "edit":
        setMode("view");
        return;
      case "view":
        setMode("edit");
        return;
      default:
        throw new UnhandledError(mode, "toggleMode");
    }
  };
  const showCredentials = canSeeCredentials(allowedActions);
  const letEditCredentials = canEditCredentials(allowedActions);
  const {
    data,
    isLoading,
    refetch: refetchCredentials,
  } = useQuery(
    `group-credentials-${identifier}`,
    () => getCredentials(identifier),
    {
      enabled: showCredentials,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  if (!showCredentials) {
    return null;
  }

  return (
    <>
      {isLoading ? <Skeleton /> : null}
      {data ? (
        <Space size="large" direction="vertical">
          <a
            href={group?.platform.infoWebURL}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Typography.Text>
              Login Link:{" "}
              <Typography.Text copyable>
                {group?.platform.infoWebURL}
              </Typography.Text>
            </Typography.Text>{" "}
            <LinkOutlined />
          </a>
          <Row gutter={20} justify="space-between">
            <Col span={20}>
              {mode === "view" ? (
                <ViewCredentials credentials={data} />
              ) : (
                <EditCredentials
                  identifier={identifier}
                  defaultValues={data}
                  credentialsType={data.credentialsType}
                  onSuccess={() => {
                    refetch();
                    refetchCredentials();
                  }}
                />
              )}
            </Col>
            <Col span={4}>
              {letEditCredentials && (
                <Button type="primary" size="large" onClick={toggleMode}>
                  {mode === "edit" ? "View" : "Edit"}
                </Button>
              )}
            </Col>
          </Row>
          <iframe
            src={group?.platform.infoWebURL}
            title="Platform Login"
            width="100%"
            height="700px"
          />
        </Space>
      ) : null}
    </>
  );
};

const ViewCredentials: FC<{ credentials: GroupCredentials }> = (props) => {
  const { credentials: data } = props;
  return (
    <Descriptions column={1}>
      <Descriptions.Item label={KEY_LABEL_MAPPER[data.credentialsType].secret1}>
        <HiddenText value={data.secret1} />
      </Descriptions.Item>
      {data.secret2 ? (
        <Descriptions.Item
          label={KEY_LABEL_MAPPER[data.credentialsType].secret2}
        >
          <HiddenText value={data.secret2} />
        </Descriptions.Item>
      ) : null}

      {data.addressText ? (
        <Descriptions.Item label="Address">
          <HiddenText value={data.addressText} />
        </Descriptions.Item>
      ) : null}

      {data.placesId ? (
        <Descriptions.Item label="Places Id">
          <HiddenText value={data.placesId} />
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

const HiddenText: FC<{ value: string }> = (props) => {
  const { value } = props;
  const [hidden, setHidden] = useState(true);
  return (
    <Space direction="horizontal" size="large">
      <Typography.Text copyable={!hidden}>
        {hidden ? "********" : value}
      </Typography.Text>
      {hidden ? (
        <EyeOutlined onClick={() => setHidden(false)} />
      ) : (
        <EyeInvisibleOutlined onClick={() => setHidden(true)} />
      )}
    </Space>
  );
};
