import {
  Row,
  Typography,
  Button,
  Checkbox,
  Space,
  Col,
  message,
  Skeleton,
} from "antd";
import { FC } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getUserPermissions,
  updateUserPermissions as updateUserPermissionsAPI,
} from "../../common/api/users";
import { toCapital } from "../../common/utils";

export const PermissionsForm: FC<{
  onFinish?: () => void;
  userId: number;
  disabled?: boolean;
}> = (props) => {
  const { onFinish, userId, disabled } = props;
  const permissionsKey = ["permissions", userId];
  const {
    data: permissions,
    refetch,
    error,
    isLoading,
  } = useQuery(permissionsKey, () => getUserPermissions(userId), {
    onSuccess: (response) => {
      console.log("getUserPermissions", response);
    },
    onError: (e) => {
      console.log("getUserPermissions", e);
    },
  });
  const queryClient = useQueryClient();
  const { mutate: updateUserPermissions } = useMutation(
    updateUserPermissionsAPI,
    {
      onMutate: async (permission) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(permissionsKey);

        // Snapshot the previous value
        const perviousPermissions = queryClient.getQueryData([
          "permissions",
          userId,
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData(
          permissionsKey,
          (oldPermissions: typeof permissions) => ({
            ...oldPermissions,
            [permission.permission]: permission.action === "grant",
          })
        );

        // Return a context object with the snapshotted value
        return { perviousPermissions };
      },
      // If the mutation fails, use the context returned from onMutate to roll back

      onError: (err, newTodo, context: any) => {
        message.error("Error in updating permission");
        queryClient.setQueryData(permissionsKey, context.perviousPermissions);
      },

      // Always refetch after error or success:

      onSettled: () => {
        queryClient.invalidateQueries(permissionsKey);
      },
    }
  );

  if (isLoading) {
    return <Skeleton />;
  }
  if (error) {
    return (
      <Row justify="center">
        <Typography.Text>Some error occurred!</Typography.Text>
        <Button type="link" onClick={() => refetch()}>
          Retry
        </Button>
      </Row>
    );
  }

  if (permissions) {
    return (
      <Space direction="vertical" size="large">
        <Typography.Title level={5} style={{ margin: 0 }}>
          Add / Remove Permissions
        </Typography.Title>
        <Row gutter={12}>
          {Object.keys(permissions).map((permission) => (
            <Col span={24}>
              <Checkbox
                checked={permissions[permission]}
                disabled={disabled}
                onChange={(event) => {
                  updateUserPermissions(
                    {
                      permission,
                      userId,
                      action: event.target.checked ? "grant" : "revoke",
                    },
                    {
                      onSuccess: () => {
                        refetch();
                      },
                    }
                  );
                }}
              >
                {permission.split("_").map(toCapital).join(" ")}
              </Checkbox>
            </Col>
          ))}
        </Row>
        {!disabled && (
          <Row justify="center">
            <Button size="large" onClick={onFinish} type="primary">
              Done
            </Button>
          </Row>
        )}
      </Space>
    );
  }

  return null;
};
