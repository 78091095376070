import { Table } from "antd";
import { FC } from "react";
import {
  GroupsAsOwner,
  formatDate,
  RELATIONSHIP_ITEM_PER_PAGE,
} from "../../common";
import { GroupId } from "../../groups/components/GroupId";
import { GroupStatus } from "../../groups/components/GroupStatus";

export const GroupsAsOwnerTable: FC<{
  dataSource: GroupsAsOwner[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
}> = (props) => {
  const { dataSource, loading, onPageChange, total } = props;
  return (
    <Table
      dataSource={dataSource}
      loading={loading}
      scroll={{ x: true }}
      size="small"
      columns={[
        {
          title: "Group",
          dataIndex: "mask",
          render: (value: GroupsAsOwner["mask"], record: GroupsAsOwner) => (
            <GroupId mask={value} value={record.id} />
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (value: GroupsAsOwner["status"]) => (
            <GroupStatus status={value} />
          ),
        },
        {
          title: "Total Slots",
          dataIndex: "slot_num_total",
          key: "slot_num_total",
        },
        {
          title: "Available Slots",
          dataIndex: "slot_num_available",
          key: "slot_num_available",
        },
        {
          title: "Created At",
          dataIndex: "created_at",
          key: "created_at",
          render: (value: GroupsAsOwner["created_at"]) => formatDate(value),
        },
        {
          title: "Platform",
          dataIndex: ["platform", "name"],
          key: "platform.name",
        },
      ]}
      pagination={{
        total,
        pageSize: RELATIONSHIP_ITEM_PER_PAGE,
      }}
      onChange={(pagination) =>
        pagination.current && onPageChange(pagination.current)
      }
    />
  );
};
