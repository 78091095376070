import { FC } from "react";
import { useMutation } from "react-query";
import {
  APIError,
  Credentials,
  CredentialsTypes,
  updateCredentials as updateCredentialsAPI,
} from "../../common";
import { EmailPasswordForm } from "./EmailPasswordForm";
import { LinkAddressForm } from "./LinkAddressForm";
import { message, notification } from "antd";

const FormComponentMap: Record<CredentialsTypes, typeof EmailPasswordForm> = {
  email_pwd: EmailPasswordForm,
  link_address: LinkAddressForm,
  // TODO: Make their own components when required!
  user_pwd: EmailPasswordForm,
  phone_pwd: EmailPasswordForm,
};

export const EditCredentials: FC<{
  identifier: string;
  credentialsType: CredentialsTypes;
  defaultValues: Partial<Credentials>;
  onSuccess?: () => void;
}> = (props) => {
  const {
    identifier: groupMask,
    credentialsType,
    defaultValues,
    onSuccess,
  } = props;

  const { mutate: updateCredentials, status: updateCredStatus } = useMutation(
    updateCredentialsAPI,
    {
      onError: (e) => {
        const error = e as APIError;
        message.error(error.data?.errors.message);
      },
      onSuccess: () => {
        onSuccess?.();
        notification.success({
          message: "Credentials Updated Successfully!",
        });
      },
    }
  );

  const handleSubmit = async (data: Credentials) => {
    updateCredentials({
      credentialsType: credentialsType,
      secret1: data.secret1,
      secret2: data.secret2,
      addressText: data.addressText,
      placesId: data.placesId,
      mask: groupMask,
    });
  };

  const FormComponent = FormComponentMap[credentialsType];
  const loading = updateCredStatus === "loading";
  return (
    <FormComponent
      loading={loading}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    />
  );
};
