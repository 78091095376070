import { Typography, Table, Tag, Modal } from "antd";
import { FC, useState } from "react";
import {
  formatDate,
  PaymentMethods,
  RELATIONSHIP_ITEM_PER_PAGE,
  toCapital,
} from "../../common";
import {
  CloseCircleFilled,
  CheckCircleFilled,
  CheckSquareFilled,
} from "@ant-design/icons";
import ReactJson from "react-json-view";

export const PaymentMethodsTable: FC<{
  dataSource: PaymentMethods[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
}> = (props) => {
  const { dataSource, loading, onPageChange, total } = props;
  const [metadata, setMetadata] = useState<Record<string, string> | null>(null);
  return (
    <>
      <Modal
        title="Metadata"
        footer={false}
        visible={!!metadata}
        destroyOnClose
        onCancel={() => setMetadata(null)}
        width="80%"
      >
        {metadata && <ReactJson src={metadata} />}
      </Modal>
      <Table
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: true }}
        size="small"
        columns={[
          {
            title: "Payment Method ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Is Active",
            dataIndex: "active",
            key: "active",
            render: (value: PaymentMethods["active"]) =>
              value ? (
                <CheckCircleFilled style={{ color: "green" }} />
              ) : (
                <CloseCircleFilled style={{ color: "red" }} />
              ),
          },
          {
            title: "Is Default",
            dataIndex: "default",
            key: "default",
            render: (value: PaymentMethods["default"]) =>
              value ? <CheckSquareFilled style={{ color: "green" }} /> : null,
          },
          {
            title: "Automatic Transactions",
            dataIndex: "automatic_transactions",
            key: "automatic_transactions",
            render: (value: PaymentMethods["automatic_transactions"]) => (
              <Typography.Text>{value ? "ON" : "OFF"}</Typography.Text>
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (value: PaymentMethods["action"]) => (
              <Tag>{value.toUpperCase()}</Tag>
            ),
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (value: PaymentMethods["type"]) => (
              <Typography.Text>{toCapital(value)}</Typography.Text>
            ),
          },
          {
            title: "Payment Gateway Account",
            dataIndex: "paygw_account",
            key: "paygw_account",
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: PaymentMethods["created_at"]) => formatDate(value),
          },
          {
            title: "Payment Gateway Object",
            dataIndex: "paygw_object",
            key: "paygw_object",
            render: (value: PaymentMethods["paygw_object"]) => value || "--",
          },
          {
            title: "Meta",
            dataIndex: "metadata",
            key: "metadata",
            render: (value: PaymentMethods["metadata"]) =>
              value ? (
                <div
                  onClick={() => setMetadata(value)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography.Text underline>View Meta</Typography.Text>
                </div>
              ) : null,
          },
        ]}
        pagination={{
          total,
          pageSize: RELATIONSHIP_ITEM_PER_PAGE,
        }}
        onChange={(pagination) =>
          pagination.current && onPageChange(pagination.current)
        }
      />
    </>
  );
};
