import { Redirect, Router as ReactRouter } from "@reach/router";
import { FC } from "react";
import { Login } from "../../auth/components/Login";
import { PlatformsList } from "../../platforms/components/PlatformsList";
import { useUser } from "../../auth/components/UserContext";
import { UsersList } from "../../users/components/UsersList";
import { GroupsList } from "../../groups/components/GroupsList";
import { ROUTES } from "../constants";
import { GroupDetailsWrapper } from "../../groups/components/GroupDetails";
import { UserDetailsWrapper } from "../../users/components/UserDetails";
import { BulkTagging } from "../../bulkTagging/components/BulkTagging";

export const Router: FC = () => {
  const { user } = useUser();

  if (user) {
    return (
      <ReactRouter>
        <PlatformsList path="/platforms" />
        <UsersList path="/users" />
        <GroupsList path="/groups" />
        <GroupDetailsWrapper path={ROUTES.getGroupDetailsRoute()} />
        <UserDetailsWrapper path={ROUTES.getUserDetailsRoute()} />
        <BulkTagging path={ROUTES.getBulkTaggingRoute()} />
        <Redirect to="/platforms" from="*" noThrow />
      </ReactRouter>
    );
  } else {
    return (
      <ReactRouter>
        <Login path="/login" />
        <Redirect to="/login" from="*" noThrow />
      </ReactRouter>
    );
  }
};
