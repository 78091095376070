import { Button, ButtonProps, Modal, notification } from "antd";
import { FC } from "react";
import { useMutation } from "react-query";
import {
  APIError,
  canActivateGroupStatus,
  updateGroupStatus,
} from "../../common";
import { useGroup } from "./GroupContext";
import { CheckOutlined } from "@ant-design/icons";

export const GroupActivateButton: FC<
  ButtonProps & { onSuccess?: () => void; onError?: (e: any) => void }
> = (props) => {
  const { disabled, onSuccess, onError, ...buttonProps } = props;
  const { group, allowedActions, refetch, isLoading } = useGroup();
  const { mutate: updateStatus, isLoading: loadingUpdate } =
    useMutation(updateGroupStatus);
  const handleActivate = () => {
    Modal.confirm({
      title: "Are you sure you want to activate this group?",
      content:
        "NOTE: After activation new users can start subscribing to this group",
      onOk: () => {
        updateStatus(
          { mask: group?.mask!, status: "active" },
          {
            onSuccess: () => {
              refetch();
              onSuccess?.();
            },
            onError: (e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.errorCode,
              });
              onError?.(e);
            },
          }
        );
      },
    });
  };
  if (!group || !canActivateGroupStatus(group, allowedActions)) {
    return null;
  }
  return (
    <Button
      type="primary"
      {...buttonProps}
      loading={loadingUpdate}
      disabled={disabled || isLoading}
      onClick={handleActivate}
      icon={<CheckOutlined />}
    >
      Activate
    </Button>
  );
};
