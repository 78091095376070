import { RouteComponentProps } from "@reach/router";
import { Typography, Skeleton, Row, Col } from "antd";
import { FC } from "react";
import { Layout, RouteParamMissing } from "../../common";
import { UserDetailsProvider, useUserDetails } from "./UserDetailsContext";
import { UserInfo } from "./UserInfo";
import { UserDetailedData } from "./UserDetailedData";

export const UserDetailsWrapper: FC<RouteComponentProps & { userId?: string }> =
  (props) => {
    const { userId } = props;
    if (!userId) {
      throw new RouteParamMissing("userId");
    }
    return (
      <UserDetailsProvider userId={Number(userId)}>
        <UserDetails />
      </UserDetailsProvider>
    );
  };

const UserDetails: FC = (props) => {
  const { isLoading, user, error, refetch, allowedActions } = useUserDetails();
  return (
    <Layout title={""}>
      {isLoading ? <Skeleton /> : null}
      {user ? (
        <Row>
          <Col span={24}>
            <UserInfo
              user={user}
              refetch={refetch}
              allowedActions={allowedActions}
            />
            <UserDetailedData userId={user.id} />
          </Col>
        </Row>
      ) : null}
      {error ? (
        <Typography.Paragraph>
          Some error occurred.
          <br />
          Please contact <a href="mailto:rajat@pulpo.club">rajat@pulpo.club</a>
        </Typography.Paragraph>
      ) : null}
    </Layout>
  );
};
