import { captureException } from "./exceptionHandler";

export class ContextNotFoundError extends Error {
  constructor(name: string) {
    super();
    this.message = `ContextError: No ${name}Context was found. Try wrapping your component with ${name}Provider`;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ContextNotFoundError)
    }
    captureException(this.message);
  }
}

export class UnhandledError extends Error {
  constructor(value: never, suffix: string) {
    super();
    this.message = `Unhandled Error: ${suffix}`;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnhandledError)
    }
    captureException(this.message);
  }
}

export class RouteParamMissing extends Error {
  constructor(paramName: string) {
    super();
    this.message = `Route Param Missing: ${paramName}`

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RouteParamMissing)
    }
    captureException(this.message);
  }
}