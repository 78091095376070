import { Button, Tooltip, Modal, notification } from "antd";
import { FC } from "react";
import { rejectMember as rejectMemberAPI, APIError } from "../../common";
import { CloseOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

export const RejectMemberButton: FC<{
  memberId: number;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  groupMask: string;
}> = (props) => {
  const { memberId, onSuccess, onError, groupMask } = props;
  const { mutate: rejectMember, isLoading } = useMutation(rejectMemberAPI);
  const handleRejectMember = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel the member?",
      content: "NOTE: This will send communications to the member.",
      onOk: () => {
        rejectMember(
          {
            mask: groupMask,
            memberId,
          },
          {
            onSuccess: () => {
              onSuccess?.();
            },
            onError: (e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.errorCode,
              });
              onError?.(e);
            },
          }
        );
      },
    });
  };
  return (
    <Tooltip title="Cancel Member">
      <Button
        icon={<CloseOutlined />}
        onClick={handleRejectMember}
        shape="circle"
        loading={isLoading}
        danger
      />
    </Tooltip>
  );
};
