import { Button, ButtonProps, Modal, notification } from "antd";
import { FC } from "react";
import { useMutation } from "react-query";
import {
  APIError,
  canInitiateValidationRequest,
  initiateValidationRequest,
} from "../../common";
import { useGroup } from "./GroupContext";

export const InitiateValidationRequestButton: FC<
  ButtonProps & { onSuccess?: () => void; onError?: (e: any) => void }
> = (props) => {
  const { disabled, onSuccess, onError, ...buttonProps } = props;
  const { group, refetch, allowedActions, isLoading } = useGroup();
  const { mutate: initiateRequest, isLoading: loadingUpdate } = useMutation(
    initiateValidationRequest
  );
  const handleFinish = () => {
    Modal.confirm({
      title: "Are you sure you want to initiate validation for this group?",
      onOk: () => {
        initiateRequest(group?.mask!, {
          onSuccess: () => {
            notification.success({
              message: "Validation Request Queued",
            });
            refetch();
            onSuccess?.();
          },
          onError: (e) => {
            const error = e as APIError;
            notification.error({
              message: error.data?.errors.message,
              description: error.data?.errors.errorCode,
            });
            onError?.(e);
          },
        });
      },
    });
  };
  if (!group || !canInitiateValidationRequest(allowedActions)) {
    return null;
  }
  return (
    <Button
      type="default"
      {...buttonProps}
      loading={loadingUpdate}
      disabled={disabled || isLoading}
      onClick={handleFinish}
    >
      Initiate Validation
    </Button>
  );
};
