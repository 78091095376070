import { Typography, Space } from "antd";
import { FC } from "react";
import { ROUTES } from "../../common";
import { LinkOutlined } from "@ant-design/icons";

export const UserId: FC<{
  value: number;
  firstName?: string;
  lastName?: string;
}> = (props) => {
  const { value, firstName, lastName } = props;
  const displayValue = firstName
    ? `${firstName} ${lastName ? ` ${lastName}` : ""}`
    : value;
  return (
    <a
      href={ROUTES.getUserDetailsRoute(value.toString())}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Space direction="horizontal" size="small">
        <LinkOutlined />
        <Typography.Text>{displayValue}</Typography.Text>
      </Space>
    </a>
  );
};
