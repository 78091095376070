import { FC } from "react";
import { QueryClientProvider } from "react-query";
import { ConfigProvider } from "./auth/components/ConfigContext";
import { UserProvider } from "./auth/components/UserContext";
import { Router } from "./common";
import { queryClient } from "./queryUtils";
import { ReactQueryDevtools } from "react-query/devtools";

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <UserProvider>
          <Router />
        </UserProvider>
      </ConfigProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
