import { FC, useState } from "react";
import { useQuery } from "react-query";
import {
  getGroupMembers,
  GROUP_CACHE_KEYS,
  RELATIONSHIP_ITEM_PER_PAGE,
} from "../../common";
import { useGroup } from "./GroupContext";
import { Members } from "./Members";

export const GroupMembers: FC<{ identifier: string }> = (props) => {
  const { identifier } = props;
  const [page, setPage] = useState(1);
  const { refetch: refetchGroup } = useGroup();
  const {
    data,
    isLoading,
    refetch: refetchMembers,
  } = useQuery(
    [GROUP_CACHE_KEYS.MEMBERS(identifier), page],
    () =>
      getGroupMembers(identifier, {
        itemsPerPage: RELATIONSHIP_ITEM_PER_PAGE,
        page,
      }),
    {
      keepPreviousData: true,
    }
  );

  const handleActionsSuccess = () => {
    refetchGroup();
    refetchMembers();
  };

  return (
    <Members
      dataSource={data?.data.data.relation_data.data || []}
      loading={isLoading}
      onPageChange={setPage}
      total={data?.data.data.relation_data.total || 0}
      onActionSuccess={handleActionsSuccess}
    />
  );
};
