import { FC, useState } from "react";
import { Group, Layout } from "../../common";
import { PageHeader, Table, Button, Modal, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "@reach/router";
import { EditFee } from "./EditFee";
import { EditSlots } from "./EditSlots";
import { EditStatus } from "./EditStatus";
// import { EditCredentials } from "./EditCredentials";

export const GroupsList: FC<RouteComponentProps> = (props) => {
  const [credentialsVisible, setCredentialsVisible] = useState(false);
  const [, setCurrentGroup] = useState<Group | null>(null);
  const handleEditCredentials = (group: Group) => {
    setCurrentGroup(group);
    setCredentialsVisible(true);
  };

  const groups: Group[] = [
    {
      mask: "GSGKL4NHNQA",
      slotsLeft: 4,
      feePerSlot: "4.99",
      status: "active",
    },
  ];
  return (
    <Layout>
      <Modal
        footer={null}
        destroyOnClose
        visible={!!credentialsVisible}
        title="Credentials"
        onCancel={() => setCredentialsVisible(false)}
      >
        {/* {currentGroup ? (
          <EditCredentials identifier={currentGroup.mask} />
        ) : null} */}
      </Modal>
      <PageHeader style={{ paddingLeft: 0, paddingRight: 0 }} title="Groups" />
      <Table
        // TODO: Add loader for users fetch
        // loading={
        //   status === "loading"
        //     ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
        //     : false
        // }
        dataSource={groups}
        pagination={false}
        columns={[
          {
            title: "Slots",
            key: "slots",
            dataIndex: "slots",
            render: (value, group) => (
              // TODO: onEdit = refetch of the group list
              <EditSlots group={group} onEdit={() => {}} />
            ),
          },
          {
            title: "Fee Per Slot",
            key: "feePerSlot",
            dataIndex: "feePerSlot",
            render: (value, group) => (
              // TODO: onEdit = refetch of the group list
              <EditFee group={group} onEdit={() => {}} />
            ),
          },
          {
            title: "Credentials",
            key: "credentials",
            dataIndex: "credentials",
            render: (value, group) => (
              <Tooltip title="Click to View/Edit">
                <Button
                  type="link"
                  onClick={() => handleEditCredentials(group)}
                >
                  Credentials <LockOutlined />
                </Button>
              </Tooltip>
            ),
          },
          {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (value, group) => (
              // TODO: onEdit = refetch of the group list
              <EditStatus group={group} onEdit={() => {}} />
            ),
          },
        ]}
      />
    </Layout>
  );
};
