import { getAuthHeader, pulpoAPI } from "../fetch";
import { User } from "../types";

export const login = async (user: { email: string; password: string }) => {
  const response = await pulpoAPI<{
    token: string;
    user: any;
  }>({
    method: "POST",
    url: "/v1/login",
    data: user,
  });
  const modeledResponse: {
    token: string;
    user: User;
  } = {
    token: response.data.data.token,
    user: {
      id: response.data.data.user.id,
      firstName: response.data.data.user.first_name,
      lastName: response.data.data.user.last_name,
      email: response.data.data.user.email,
      language: response.data.data.user.language,
    },
  };
  return modeledResponse;
};

export const logout = () => {
  return pulpoAPI({
    method: "POST",
    url: "/v1/logout",
    headers: { ...getAuthHeader() },
  });
};
