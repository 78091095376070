import { TagProps, Tag } from "antd";
import { FC, ReactNode } from "react";
import { FinanceEntryType } from "../../common";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export const RenderFinanceEntryType: FC<{ value: FinanceEntryType }> = (
  props
) => {
  const { value } = props;
  const color: NonNullable<TagProps["color"]> = (
    {
      credit: "success",
      debit: "warning",
    } as Record<FinanceEntryType, NonNullable<TagProps["color"]>>
  )[value];
  const icon = (
    {
      credit: <ArrowDownOutlined />,
      debit: <ArrowUpOutlined />,
    } as Record<FinanceEntryType, ReactNode>
  )[value];
  return (
    <Tag color={color} icon={icon}>
      {value.toLocaleUpperCase()}
    </Tag>
  );
};
