import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { FC } from "react";
import { useMutation } from "react-query";
import { useConfig } from "../../auth/components/ConfigContext";
import { APIError, STANDARD_PASSWORD, UnhandledError } from "../../common";
import {
  createUser as createUserAPI,
  updateUser as updateUserAPI,
} from "../../common/api/users";
import { distinct, toCapital } from "../../common/utils";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  phoneNumber?: string | null;
  phoneCountry?: string | null;
};

export const UserForm: FC<{
  onFinish?: (id: number) => void;
  mode?: "create" | "edit";
  initialValues?: Partial<FormData> & { id: number };
}> = (props) => {
  const { onFinish, mode = "create", initialValues } = props;
  const [form] = Form.useForm();
  const { countryId, countries } = useConfig();
  const languages = distinct(countries.flatMap((country) => country.languages));
  const { mutate: createUser, isLoading: loadingCreate } =
    useMutation(createUserAPI);
  const { mutate: updateUser, isLoading: loadingUpdate } =
    useMutation(updateUserAPI);

  const handleFinish = (values: any) => {
    switch (mode) {
      case "edit":
        handleEdit(values);
        return;
      case "create":
        handleCreate(values);
        return;
      default:
        throw new UnhandledError(mode, "UserForm");
    }
  };
  const handleError = (e: any) => {
    // TODO: Find a better way to type this!!!!
    const error = e as APIError;
    if (error.data?.errors.validator) {
      const errors = error.data?.errors.validator;
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key],
        }))
      );
    }
  };
  const phoneNumberParser = (phoneNumber: string) => {
    const parsed = parsePhoneNumber(phoneNumber);
    return {
      phoneCountry: parsed?.countryCallingCode
        ? `+${parsed.countryCallingCode}`
        : undefined,
      phoneNumber: parsed?.nationalNumber,
    };
  };
  const handleCreate = (values: any) => {
    createUser(
      {
        // TODO: Payload creation should be inside API function
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        password: values.password,
        role: "staff",
        country_id: countryId,
        language: values.language,
      },
      {
        onSuccess: (response) => {
          onFinish?.(response.user.id);
        },
        onError: handleError,
      }
    );
  };
  const handleEdit = (values: any) => {
    const { phoneCountry, phoneNumber } = phoneNumberParser(
      values.phoneNumber || ""
    );
    // If email is changed, we need confirmation
    if (initialValues?.email !== values.email) {
      Modal.confirm({
        title: "Are you sure you want to update the email?",
        content:
          "This action will disable Social Login and Delete the link with Marketing Automation and Helpdesk Tool, please, confirm this change",
        onOk: () => {
          updateUser(
            {
              firstName: values.firstName,
              lastName: values.lastName,
              language: values.language,
              password: values.password,
              userId: initialValues?.id!,
              email: values.email,
              phoneCountry,
              phoneNumber,
            },
            {
              onSuccess: (response) => {
                onFinish?.(response.user.id);
              },
              onError: handleError,
            }
          );
        },
      });
    } else {
      updateUser(
        {
          firstName: values.firstName,
          lastName: values.lastName,
          language: values.language,
          password: values.password,
          userId: initialValues?.id!,
          phoneCountry,
          phoneNumber,
        },
        {
          onSuccess: (response) => {
            onFinish?.(response.user.id);
          },
          onError: handleError,
        }
      );
    }
  };
  const loading = loadingCreate || loadingUpdate;
  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      validateMessages={{
        required: "This is required",
        types: { url: "Enter a valid URL" },
      }}
      onFinish={handleFinish}
      initialValues={{
        ...initialValues,
        password: mode === "create" ? STANDARD_PASSWORD : undefined,
        phoneNumber: initialValues?.phoneNumber
          ? `${initialValues.phoneCountry}${initialValues.phoneNumber}`
          : undefined,
      }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            label="First Name"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            label="Last Name"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="email"
        label="Email"
        required
        rules={[
          { required: true },
          { type: "email", message: "Enter a valid email" },
        ]}
      >
        <Input placeholder="Email" type="email" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        required
        rules={[{ required: mode === "create" }]}
      >
        <Input placeholder="Password" type="text" />
      </Form.Item>

      {mode === "edit" && (
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              validator: (_, value) => {
                const { phoneNumber } = phoneNumberParser(value || "");
                if (!phoneNumber || phoneNumber.length > 10) {
                  console.log("error", phoneNumber);
                  return Promise.reject(new Error("Invalid phone number"));
                } else {
                  console.log("success", phoneNumber);
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <PhoneInput
            defaultCountry="ES"
            inputMode="tel"
            // @ts-expect-error
            countryCallingCodeEditable={false}
            international
          />
        </Form.Item>
      )}

      <Form.Item
        name="language"
        label="Language"
        required
        rules={[{ required: true }]}
      >
        <Select
          options={languages.map((language) => ({
            label: language,
            value: language,
          }))}
          placeholder="Language"
        />
      </Form.Item>

      <Row gutter={12} justify="end">
        <Col>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {toCapital(mode)}
            </Button>
          </Form.Item>
        </Col>

        <Col>
          <Form.Item>
            <Button
              size="large"
              htmlType="reset"
              onClick={() => form.resetFields()}
            >
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
