import { TagProps, Tag } from "antd";
import { FC } from "react";
import { FinanceEntryStatus, toCapital } from "../../common";

export const RenderFinanceStatus: FC<{ value: FinanceEntryStatus }> = (
  props
) => {
  const { value } = props;
  const color: NonNullable<TagProps["color"]> = (
    {
      effective: "success",
      onhold: "warning",
    } as Record<FinanceEntryStatus, NonNullable<TagProps["color"]>>
  )[value];
  return <Tag color={color}>{toCapital(value)}</Tag>;
};
