import { Typography, Space } from "antd";
import { FC } from "react";
import { ROUTES } from "../../common";
import { LinkOutlined } from "@ant-design/icons";

export const GroupId: FC<{ value: number; mask?: string }> = (props) => {
  const { value, mask } = props;
  return (
    <a
      href={ROUTES.getGroupDetailsRoute(value.toString())}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Space direction="horizontal" size="small">
        <LinkOutlined />
        <Typography.Text>{mask || value}</Typography.Text>
      </Space>
    </a>
  );
};
