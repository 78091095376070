import { Button, Form, Tooltip, Modal, notification, Input } from "antd";
import { FC, useState } from "react";
import { APIError, changeRemoveMember } from "../../common";
import { LogoutOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";

export const LeaveMemberButton: FC<{
  memberId: number;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  groupMask: string;
}> = (props) => {
  const { memberId, onSuccess, onError, groupMask } = props;
  const [visible, setVisible] = useState(false);
  const { mutate: changeRemove, isLoading } = useMutation(changeRemoveMember);
  const handleLeave = ({ reason }: { reason?: string }) => {
    changeRemove(
      {
        forceLeaving: true,
        mask: groupMask,
        trigger: "afe_staff_action",
        changeGroup: false,
        stopIfNoAvailableGroup: false,
        groupMemberId: memberId,
        reason,
      },
      {
        onSuccess: () => {
          setVisible(false);
          onSuccess?.();
        },
        onError: (e) => {
          const error = e as APIError;
          notification.error({
            message: error.data?.errors.message,
            description: error.data?.errors.errorCode,
          });
          onError?.(e);
        },
      }
    );
  };
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={false}
        destroyOnClose
      >
        <RemoveFinishDetailsForm onFinish={handleLeave} loading={isLoading} />
      </Modal>
      <Tooltip title="Leave Member">
        <Button
          icon={<LogoutOutlined />}
          shape="circle"
          onClick={() => setVisible(true)}
          loading={isLoading}
        />
      </Tooltip>
    </>
  );
};

const RemoveFinishDetailsForm: FC<{
  onFinish: ({ reason }: { reason?: string }) => void;
  loading: boolean;
}> = (props) => {
  const { onFinish, loading } = props;
  return (
    <Form
      layout="vertical"
      onFinish={(values) => onFinish({ reason: values.reason })}
    >
      <Form.Item name="reason" label="Notes">
        <Input.TextArea placeholder="Notes" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
