import { Space, Tag, Button, Modal, Select, notification, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateUser as updateUserAPI } from "../api/users";
import { updateInternalData } from "../api/group";
import { UnhandledError } from "..";

export const EntityTags: FC<{
  tags: string[];
  refetch: () => void;
  entityId: number | string;
  entityType: "user" | "group";
  canUpdate: boolean;
}> = (props) => {
  const { tags, refetch, entityId, entityType, canUpdate } = props;
  const [isOpen, setOpen] = useState(false);
  const [updatedTags, setUpdatedTags] = useState<string[]>(tags);

  const { mutate: updateUser, isLoading: loadingUser } =
    useMutation(updateUserAPI);
  const { mutate: updateGroupTags, isLoading: loadingGroup } =
    useMutation(updateInternalData);

  // Set in state the latest tags from props
  const tagsString = tags.join(",");
  useEffect(
    () => {
      if (tagsString !== updatedTags.join(",")) {
        setUpdatedTags(tags);
      }
    },
    // eslint-disable-next-line
    [tagsString]
  );

  const onUpdateSuccess = () => {
    refetch();
    setOpen(false);
    setUpdatedTags(tags);
  };

  const onUpdateFailed = (e: any) => {
    notification.error({
      message: "Oop! Some error occurred. Try again!",
      description: String(e),
    });
  };

  const handleSave = () => {
    switch (entityType) {
      case "user": {
        updateUser(
          {
            // Typecasting this since for the user it will always be number
            // and for group it will be the mask
            userId: entityId as number,
            tags: updatedTags,
          },
          {
            onSuccess: onUpdateSuccess,
            onError: onUpdateFailed,
          }
        );
        return;
      }
      case "group": {
        updateGroupTags(
          {
            // Typecasting this since for the user it will always be number
            // and for group it will be the mask
            mask: entityId as string,
            tags: updatedTags,
          },
          {
            onSuccess: onUpdateSuccess,
            onError: onUpdateFailed,
          }
        );
        return;
      }
      default:
        throw new UnhandledError(
          entityType,
          `Entity Tags: Unknown entity type: ${entityType}`
        );
    }
  };

  const isLoading = loadingUser || loadingGroup;
  return (
    <>
      <Modal
        title="Add/View All Tags for this user"
        visible={isOpen}
        onOk={handleSave}
        okButtonProps={{ loading: isLoading, disabled: !canUpdate }}
        onCancel={() => setOpen(false)}
        okText="Save"
        cancelText="Discard"
        bodyStyle={{ minHeight: 200 }}
      >
        <Select
          mode="tags"
          options={updatedTags.map((tag) => ({ label: tag, value: tag }))}
          onChange={(value) => setUpdatedTags(value as string[])}
          style={{ width: "100%" }}
          placeholder="Select Tags"
          value={updatedTags}
          disabled={!canUpdate}
        />
      </Modal>
      <Space direction="horizontal" size="small">
        {/* Show only the top 5 tags here */}
        <Row style={{ maxWidth: "700px", flexWrap: "wrap" }}>
          {tags.slice(0, 20).map((tag) => (
            <Tag style={{ marginBottom: "4px" }}>{tag.toUpperCase()}</Tag>
          ))}
        </Row>

        <Button size="middle" type="ghost" onClick={() => setOpen(true)}>
          Update/View All Tags
        </Button>
      </Space>
    </>
  );
};
