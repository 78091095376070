import { Typography, Table, Tag } from "antd";
import { FC, useState } from "react";
import {
  Communication,
  formatDate,
  RELATIONSHIP_ITEM_PER_PAGE,
  toCapital,
} from "../../common";
import Modal from "antd/lib/modal/Modal";
import ReactJson from "react-json-view";
import { UserId } from "./UserId";

export const Communications: FC<{
  dataSource: Communication[];
  loading: boolean;
  onPageChange: (nextPage: number) => void;
  total: number;
}> = (props) => {
  const { dataSource, loading, onPageChange, total } = props;
  const [metadata, setMetadata] = useState<Record<string, string> | null>(null);
  return (
    <>
      <Modal
        title="Metadata"
        footer={false}
        visible={!!metadata}
        destroyOnClose
        onCancel={() => setMetadata(null)}
        width="80%"
      >
        {metadata && <ReactJson src={metadata} />}
      </Modal>
      <Table
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: true }}
        size="small"
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "User",
            dataIndex: "user",
            key: "user",
            render: (value: Communication["user"]) => (
              <UserId
                value={value.id}
                firstName={value.first_name}
                lastName={value.last_name || undefined}
              />
            ),
            width: "150px",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: Communication["status"]) => (
              <Tag color={value === "sent" ? "success" : "error"}>
                {value.toUpperCase()}
              </Tag>
            ),
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (value: Communication["type"]) => (
              <Typography.Text>{toCapital(value)}</Typography.Text>
            ),
          },
          {
            title: "Gateway",
            dataIndex: "gateway",
            key: "gateway",
            render: (value: Communication["gateway"]) => (
              <Typography.Text>{toCapital(value)}</Typography.Text>
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: "300px",
          },
          {
            title: "Destination",
            dataIndex: "destination",
            key: "destination",
            render: (value: Communication["destination"]) => (
              <Typography.Text copyable>{value}</Typography.Text>
            ),
            width: "300px",
          },
          {
            title: "Template ID",
            dataIndex: "communication_template_id",
            key: "communication_template_id",
            width: "140px",
          },
          {
            title: "Sent At",
            dataIndex: "sent_at",
            key: "sent_at",
            render: (value: Communication["sent_at"]) =>
              value ? formatDate(value) : "--",
          },
          {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (value: Communication["created_at"]) => formatDate(value),
          },
          // {
          //   title: "Updated At",
          //   dataIndex: "updated_at",
          //   key: "updated_at",
          //   render: (value) => formatDate(value),
          // },
          {
            title: "UUID",
            dataIndex: "uuid",
            key: "uuid",
            render: (value: Communication["uuid"]) => (
              <Typography.Text copyable>{value}</Typography.Text>
            ),
            width: "400px",
          },
          {
            title: "Meta",
            dataIndex: "metadata",
            key: "metadata",
            render: (value: Communication["metadata"]) => (
              <div
                onClick={() => setMetadata(value)}
                style={{ cursor: "pointer" }}
              >
                <Typography.Text underline>View Meta</Typography.Text>
              </div>
            ),
          },
        ]}
        pagination={{
          total,
          pageSize: RELATIONSHIP_ITEM_PER_PAGE,
        }}
        onChange={(pagination) =>
          pagination.current && onPageChange(pagination.current)
        }
      />
    </>
  );
};
