import {
  Typography,
  Space,
  Row,
  Col,
  Descriptions,
  Divider,
  Button,
  ButtonProps,
  Modal,
  notification,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useConfig } from "../../auth/components/ConfigContext";
import {
  GroupDetails as GroupDetailsType,
  ROUTES,
  makeBooleanReadable,
  toCapital,
  GroupActions,
  canUpdateGroupTags,
  canMakeGroupIncident,
  updateGroupStatus as updateGroupStatusAPI,
  APIError,
  updateInternalData as updateInternalDataAPI,
} from "../../common";
import { GroupStatus } from "./GroupStatus";
import { GroupFinishButton } from "./GroupFinishButton";
import { GroupActivateButton } from "./GroupActivateButton";
import { useLocation } from "@reach/router";
import { EntityTags } from "../../common/components/EntityTags";
import { useGroup } from "./GroupContext";
import { useMutation } from "react-query";
import { InitiateValidationRequestButton } from "./InitiateValidationRequestButton";
import { InternalNotes } from "../../common/components/InternalNotes";

export const GroupInfo: FC<{
  group: GroupDetailsType;
  refetch: () => void;
  allowedActions: GroupActions[];
}> = (props) => {
  const { group, refetch, allowedActions } = props;
  const { formatCurrency } = useConfig();
  const location = useLocation();
  return (
    <Space direction="vertical" size="middle">
      <Space direction="vertical" size="middle">
        <Row justify="space-between">
          <Col>
            <Space align="center" size="large">
              <Space
                split={
                  <Divider
                    type="vertical"
                    style={{ borderWidth: "2px", borderColor: "gray" }}
                  />
                }
              >
                <Typography.Title level={4} style={{ margin: 0 }} copyable>
                  {group.mask}
                </Typography.Title>
                <Typography.Title level={4} style={{ margin: 0 }} copyable>
                  {group.platform.name}
                </Typography.Title>
                <Typography.Title
                  level={4}
                  style={{ margin: 0 }}
                  copyable={{
                    text:
                      location.origin +
                      ROUTES.getGroupDetailsRoute(group.id.toString()),
                    tooltips: "Copy Group URL",
                  }}
                >
                  ID: {group.id}
                </Typography.Title>
              </Space>
              <GroupStatus status={group.status} />
            </Space>
          </Col>
          <Col>
            <Space size="middle">
              <GroupActivateButton />
              <GroupIncidentButton />
              <GroupFinishButton />
              <InitiateValidationRequestButton />
            </Space>
          </Col>
          <Col span={23}>
            <EntityTags
              tags={group.tags}
              refetch={refetch}
              entityId={group.mask}
              entityType="group"
              canUpdate={canUpdateGroupTags(allowedActions)}
            />
          </Col>
        </Row>

        {/* Group Info Starts here */}
        <Descriptions title="">
          <Descriptions.Item label="Allow Enrollments">
            {makeBooleanReadable(group.allowEnrollments)}
          </Descriptions.Item>
          <Descriptions.Item label="Period Length">
            {toCapital(group.periodLength)}
          </Descriptions.Item>
          <Descriptions.Item label="Total Slots">
            {group.slotNumTotal}
          </Descriptions.Item>
          <Descriptions.Item label="Available Slots">
            {group.slotNumAvailable}
          </Descriptions.Item>
          <Descriptions.Item label="Slot Price">
            {formatCurrency(group.slotPrice, group.countryId)}
          </Descriptions.Item>
          <Descriptions.Item label="Pulpo Fee per slot">
            {formatCurrency(group.slotFee, group.countryId)}
          </Descriptions.Item>
        </Descriptions>
      </Space>

      <GroupInternalNotes
        internalNotes={group.internalNotes}
        mask={group.mask}
        refetch={refetch}
        hasUpdatePermission={canUpdateGroupTags(allowedActions)}
      />

      {/* Owner Info Starts here */}
      <Descriptions
        title={
          <Space direction="horizontal">
            <Typography.Text>Owner</Typography.Text>
            <a
              href={`${ROUTES.getUserDetailsRoute(
                group.owner.userId.toString()
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlined />
            </a>
          </Space>
        }
      >
        <Descriptions.Item label="First Name">
          {toCapital(group.owner.firstName)}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <a href={`mailto:${group.owner.email}`}>
            <Typography.Text copyable>{group.owner.email}</Typography.Text>
          </a>
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
};

const GroupInternalNotes: FC<{
  internalNotes: string;
  mask: string;
  refetch: () => void;
  hasUpdatePermission: boolean;
}> = (props) => {
  const { internalNotes, mask, refetch, hasUpdatePermission } = props;
  const { mutate: updateInternalData, isLoading } = useMutation(
    updateInternalDataAPI
  );
  const updateInternalNotes = (notes: string) => {
    return new Promise((resolve, reject) => {
      updateInternalData(
        { internalNotes: notes, mask },
        {
          onSuccess: () => {
            refetch();
            resolve(null);
          },
          onError: (e) => {
            reject(e);
          },
        }
      );
    });
  };
  return (
    <Row justify="space-between" align="middle">
      <Col span={20}>
        <Descriptions title="Internal Notes" column={1}>
          <Typography.Paragraph>{internalNotes || "-"}</Typography.Paragraph>
        </Descriptions>
      </Col>
      {hasUpdatePermission && (
        <Col>
          <InternalNotes
            buttonText="Update Notes"
            onSuccess={updateInternalNotes}
            defaultValue={internalNotes}
            loading={isLoading}
          />
        </Col>
      )}
    </Row>
  );
};

const GroupIncidentButton: FC<
  ButtonProps & { onSuccess?: () => void; onError?: (e: any) => void }
> = (props) => {
  const { disabled, onSuccess, onError, ...buttonProps } = props;
  const { group, refetch, allowedActions, isLoading } = useGroup();
  const { mutate: updateGroupStatus, isLoading: loadingUpdate } =
    useMutation(updateGroupStatusAPI);

  const handleMakeIncident = () => {
    Modal.confirm({
      title: "Are you sure you want to mark this group as INCIDENT?",
      content: "NOTE: No communication will be sent to the owner/members",
      onOk: () => {
        updateGroupStatus(
          { mask: group?.mask!, status: "incident" },
          {
            onSuccess: () => {
              refetch();
              onSuccess?.();
            },
            onError: (e) => {
              const error = e as APIError;
              notification.error({
                message: error.data?.errors.message,
                description: error.data?.errors.errorCode,
              });
              onError?.(e);
            },
          }
        );
      },
    });
  };

  if (!group || !canMakeGroupIncident(allowedActions)) {
    return null;
  }

  return (
    <Button
      type="default"
      {...buttonProps}
      loading={loadingUpdate}
      disabled={disabled || isLoading}
      onClick={handleMakeIncident}
      // icon={<DeleteOutlined />}
    >
      Mark Incident
    </Button>
  );
};
