import { Tag } from "antd";
import { FC } from "react";
import {
  GroupStatus as GroupStatusType,
  getGroupStatusColor,
  toCapital,
} from "../../common";

export const GroupStatus: FC<{ status: GroupStatusType }> = (props) => {
  const { status } = props;
  const color = getGroupStatusColor(status);
  return <Tag color={color}>{toCapital(status)}</Tag>;
};
