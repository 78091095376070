import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import { FC } from "react";
import { useMutation, useQuery } from "react-query";
import { APIError } from "../../common";
import {
  createPlatform,
  getPlatform,
  updatePlatform,
} from "../../common/api/platform";
import { LoadingOutlined } from "@ant-design/icons";
import { useConfig } from "../../auth/components/ConfigContext";
import {
  currencyFormatter,
  currencyParser,
  toCapital,
} from "../../common/utils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../queryUtils";
import * as Sentry from "@sentry/react";

export const PlatformForm: FC<{
  mode?: "create" | "edit";
  platformId?: number;
  onFinish?: () => void;
}> = (props) => {
  const [form] = Form.useForm();
  const { mode = "create", platformId, onFinish } = props;
  const { countryId, currentCountry } = useConfig();
  const { create, createStatus, update, updateStatus } =
    useCreateEditPlatform();

  // Purposefully keeping it `any`
  // If there are any keys missing or with invalid data
  // the API will respond with `validator` errors
  // which will be shown on the UI
  const handleFinish = (values: any) => {
    const method = mode === "edit" ? update : create;

    // Make the values null for optional keys
    // since BE doesn't work on undefined for now
    if (values.credentials_per_slot === undefined) {
      delete values.credentials_per_slot;
    }

    if (values.credentials_autocheck_hours === undefined) {
      delete values.credentials_autocheck_hours;
    }

    if (
      !Array.isArray(values.require_enrollment_checks) ||
      values.require_enrollment_checks.length === 0
    ) {
      delete values.require_enrollment_checks;
    }

    method(
      {
        countryId,
        // @ts-expect-error
        platformId: platformId,
        platform: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["platform", platformId], {
            exact: true,
          });
          onFinish?.();
        },
        onError: (error: APIError) => {
          if (error.data?.errors.validator) {
            const errors = error.data?.errors.validator;
            form.setFields(
              Object.keys(errors).map((key) => ({
                name: key,
                errors: errors[key],
              }))
            );
          } else {
            Sentry.captureException(error, {
              tags: {
                component: "PlatformForm",
                mode,
                platformId,
                data: values,
              },
            });
            message.error(
              "Some error occurred in the API, please contact rajat@pulpo.club"
            );
          }
        },
      }
    );
  };
  const { data: platformData, status: getPlatformStatus } = useQuery(
    ["platform", platformId],
    () => getPlatform(countryId, platformId!),
    { enabled: !!platformId && mode === "edit" }
  );
  if (getPlatformStatus === "loading") {
    return (
      <Row justify="center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </Row>
    );
  }
  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      validateMessages={{
        required: "This is required",
        types: { url: "Enter a valid URL" },
      }}
      onFinish={handleFinish}
      initialValues={mode === "edit" ? platformData?.platform : undefined}
    >
      <Form.Item required rules={[{ required: true }]} name="name" label="Name">
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="info_description"
        label="Description"
      >
        <Input.TextArea placeholder="Description" />
      </Form.Item>

      {mode === "create" && (
        <Row gutter={12}>
          <Col span={18}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name="identifier"
              label="Unique Identifier"
            >
              <Input placeholder="Unique Identifier" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name="vertical"
              label="Vertical"
            >
              <Select
                options={currentCountry.verticalsAvailable.map((vertical) => ({
                  label: toCapital(vertical),
                  value: vertical,
                }))}
                placeholder="Vertical"
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      {mode === "create" && (
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name="credentials_types"
              label="Credentials Type"
            >
              <Select
                mode="multiple"
                options={CREDENTIALS_TYPES}
                placeholder="Credentials Type"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              rules={[{ required: true }]}
              name="period_default_length"
              label="Renew Period"
            >
              <Select
                options={PERIOD_DEFAULT_LENGTH}
                placeholder="Renew Period"
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name="active" valuePropName="checked" initialValue={true}>
            <Checkbox>Active</Checkbox>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="require_owner_phone_verified"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Require Owner Phone Verification</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="credentials_validation_system"
        label="Credential Validation System"
        initialValue="automatic"
      >
        <Select
          options={CREDENTIALS_VALIDATION_SYSTEM}
          placeholder="Credential Validation System"
        />
      </Form.Item>

      <Divider>Slots</Divider>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            rules={[
              { required: false },
              { pattern: /^\d+$/, message: "Remove decimals" },
            ]}
            name="credentials_autocheck_hours"
            label="Credentials Auto Check Hours"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Credentials Auto Check Hours"
              step={6}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[
              { required: true },
              {
                pattern: /^#\w{6}$/,
                message: "Enter a valid hex code",
              },
            ]}
            name="info_color"
            label="Theme Color"
          >
            <Input placeholder="Theme Color" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_price_original"
            label="Original Slot Price"
            tooltip="Price charged by the platform. This is the cross off price shown on the UFE to show the value provided by Pulpo"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Original Slot Price"
              formatter={currencyFormatter(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              parser={currencyParser(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              step={0.5}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_price_steps"
            label="Slot Price Steps"
            tooltip="If the step is 0.05, slot prices can only be a multiple of 0.05"
            initialValue={0.05}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Slot Price Steps"
              step={0.5}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[
              { required: true },
              { pattern: /^\d+$/, message: "Remove decimals" },
            ]}
            name="period_renew_hours_before_end"
            label="Period renew hours"
            tooltip="How many hours before the period ends, the system should try to renew the period"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Period renew hours"
              step={6}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[
              { required: true },
              { pattern: /^\d+$/, message: "Remove decimals" },
            ]}
            name="period_unpaid_hours_leeway"
            label="Period unpaid leeway"
            tooltip="Hours before kicking the user out, if he has not paid for that period"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Period unpaid leeway"
              step={6}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_price_min"
            label="Min Slot Price"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Min Slot Price"
              formatter={currencyFormatter(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              parser={currencyParser(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              step={0.5}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            name="slot_price_default"
            label="Default Slot Price"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Default Slot Price"
              formatter={currencyFormatter(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              parser={currencyParser(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              step={0.5}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_price_max"
            label="Max Slot Price"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Max Slot Price"
              formatter={currencyFormatter(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              parser={currencyParser(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              step={0.5}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_fee_default"
            label="Slot Fee"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Slot Fee"
              formatter={currencyFormatter(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              parser={currencyParser(
                currentCountry.defaultLanguage,
                currentCountry.defaultCurrency
              )}
              step={0.5}
            />
          </Form.Item>
        </Col>
        {mode === "create" && (
          <Col span={8}>
            <Form.Item
              rules={[{ required: false }]}
              name="credentials_per_slot"
              label="Credentials Per Slot"
            >
              <Select
                options={CREDENTIALS_PER_SLOT}
                allowClear
                placeholder="Credentials Per Slot"
              />
            </Form.Item>
          </Col>
        )}
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="limit_owner_platform_groups_max"
            label="Max Groups per owner"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Max Groups per owner"
              step={1}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={6}>
          <Form.Item
            name="allow_groups_creation"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Allow Group Creation</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="allow_groups_enrollment"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Allow Group Enrollment</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="allow_groups_private"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Allow Private Groups</Checkbox>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="allow_groups_public"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Allow Public Groups</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_num_min"
            label="Min Slot Count"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Min Slot Count"
              step={1}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_num_default"
            label="Default Slot Count"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Default Slot Count"
              step={1}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="slot_num_max"
            label="Max Slot Count"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Max Slot Count"
              step={1}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider>Platform Meta Information</Divider>

      <Form.Item
        required
        rules={[{ required: true }, { type: "url" }]}
        name="info_url_web"
        label="Platform Web URL"
      >
        <Input type="url" placeholder="Platform Web URL" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }, { type: "url" }]}
        name="info_url_terms"
        label="Terms & Conditions URL"
      >
        <Input type="url" placeholder="Terms & Conditions URL" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }, { type: "url" }]}
        name="info_logo_colored"
        label="Colored Logo URL"
      >
        <Input type="url" placeholder="Colored Logo URL" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }, { type: "url" }]}
        name="info_logo_transparent"
        label="Transparent Logo URL"
      >
        <Input type="url" placeholder="Transparent Logo URL" />
      </Form.Item>

      <Divider>Enrollment Checks</Divider>

      <Form.List name="require_enrollment_checks">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field) => (
              <Row gutter={12} key={field.key} align="middle">
                <Col span={20}>
                  <Form.Item
                    {...field}
                    name={[field.name, "text"]}
                    fieldKey={[field.fieldKey, "text"]}
                    rules={[{ required: true }]}
                    label="Check Text"
                    noStyle
                  >
                    <Input placeholder="Check Text..." />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <MinusCircleOutlined
                    style={{ fontSize: 18 }}
                    onClick={() => remove(field.name)}
                  />
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...field}
                    name={[field.name, "required"]}
                    fieldKey={[field.fieldKey, "required"]}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>Required</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...field}
                    name={[field.name, "show_checked"]}
                    fieldKey={[field.fieldKey, "show_checked"]}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>Show Checked</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add Check
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>

      <Row gutter={12} justify="end">
        <Col>
          <Form.Item>
            <Button
              loading={createStatus === "loading" || updateStatus === "loading"}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {mode === "create" ? "Create" : "Update"}
            </Button>
          </Form.Item>
        </Col>

        <Col>
          <Form.Item>
            <Button
              size="large"
              htmlType="reset"
              onClick={() => form.resetFields()}
            >
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const useCreateEditPlatform = () => {
  const {
    mutate: create,
    status: createStatus,
    error: createError,
  } = useMutation<any, APIError, Parameters<typeof createPlatform>[0]>(
    createPlatform
  );

  const {
    mutate: update,
    status: updateStatus,
    error: updateError,
  } = useMutation<unknown, APIError, Parameters<typeof updatePlatform>[0]>(
    updatePlatform
  );

  return {
    create,
    createStatus,
    createError,

    update,
    updateStatus,
    updateError,
  };
};

const CREDENTIALS_TYPES = [
  { value: "link_address", label: "Link & Address" },
  { value: "user_pwd", label: "Username & Password" },
  { value: "phone_pwd", label: "Phone & Password" },
  { value: "email_pwd", label: "Email & Password" },
];

const PERIOD_DEFAULT_LENGTH = [
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

const CREDENTIALS_PER_SLOT = [
  {
    value: "pin_required",
    label: "Required",
  },
  {
    value: "pin_option",
    label: "Optional",
  },
];

const CREDENTIALS_VALIDATION_SYSTEM = [
  {
    label: "Manual",
    value: "manual",
  },
  {
    label: "Automatic",
    value: "automatic",
  },
  {
    label: "Not Required",
    value: "not_required",
  },
];
