import { getAuthHeader, pulpoAPI } from "../fetch";
import { Platform } from "../types";

export const getPlatforms = async (countryId: number) => {
  const response = await pulpoAPI<{
    platforms: any[];
  }>({
    method: "GET",
    url: `/v1/${countryId}/platforms`,
    headers: {
      ...getAuthHeader(),
    },
  });
  const modeledResponse: Platform[] = response.data.data.platforms.map(
    (platform) => ({
      id: platform.id,
      active: platform.active,
      name: platform.name,
      infoDescription: platform.info_description,
      infoColor: platform.info_color,
      infoURLWeb: platform.info_url_web,
      infoURLTerms: platform.info_url_terms,
      infoLogoColored: platform.info_logo_colored,
      infoLogoTransparent: platform.info_logo_transparent,
      allowGroupsCreation: platform.allow_groups_creation,
      allowGroupsEnrollment: platform.allow_groups_enrollment,
      allowGroupsPrivate: platform.allow_groups_private,
      allowGroupsPublic: platform.allow_groups_public,
    })
  );
  return modeledResponse;
};

export const getPlatform = async (countryId: number, platformId: number) => {
  const response = await pulpoAPI<{
    platform: any;
  }>({
    method: "GET",
    url: `/v1/${countryId}/platform/${platformId}`,
    headers: {
      ...getAuthHeader(),
    },
  });
  return response.data.data;
};

type CreatePlatform = {
  active: boolean;
  name: string;
  credentials_types: "link_address" | "user_pwd" | "phone_pwd" | "email_pwd";
  // TODO: @rubenvl2 What is this for?
  // "credentials_per_slot": "pin_required // pin_optional",
  credentials_autocheck_hours: number;
  period_default_length: "monthly" | "weekly" | "yearly";
  slot_price_default: number;
  slot_price_min: number;
  slot_price_max: number;
  slot_fee_default: number;
  slot_num_default: number;
  slot_num_min: number;
  slot_num_max: number;
  allow_groups_creation: boolean;
  allow_groups_enrollment: boolean;
  allow_groups_private: boolean;
  allow_groups_public: boolean;
  info_description: string;
  info_color: string;
  info_url_web: string;
  info_url_terms: string;
  info_logo_colored: string;
  info_logo_transparent: string;
};
export const createPlatform = async ({
  countryId,
  platform,
}: {
  countryId: number;
  platform: CreatePlatform;
}) => {
  const response = await pulpoAPI({
    method: "POST",
    url: `/v1/${countryId}/platform`,
    headers: {
      ...getAuthHeader(),
    },
    data: {
      ...platform,
      country_id: countryId,
    },
  });
  return response.data.data;
};

export const updatePlatform = async ({
  countryId,
  platformId,
  platform,
}: {
  countryId: number;
  platformId: number;
  platform: Omit<CreatePlatform, "period_default_length" | "credentials_types">;
}) => {
  const response = await pulpoAPI({
    method: "PUT",
    url: `/v1/${countryId}/platform/${platformId}`,
    headers: {
      ...getAuthHeader(),
    },
    data: platform,
  });
  return response.data.data;
};
