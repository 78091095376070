import { RouteComponentProps } from "@reach/router";
import { FC, useState } from "react";
import { Layout, useColumnSearch, User } from "../../common";
import { PageHeader, Button, Modal, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UserCreate } from "./UserCreate";

export const UsersList: FC<RouteComponentProps> = (props) => {
  const [createVisible, setCreateVisible] = useState(false);
  const getConfigForFirstNameSearch = useColumnSearch<User>("firstName");

  // TODO: Add users list API response
  const users: User[] = [];
  const handleCreateFinish = () => {
    // TODO: Refetch users
    setCreateVisible(false);
  };

  return (
    <Layout>
      <Modal
        footer={null}
        destroyOnClose
        visible={createVisible}
        title="Create a new User"
        onCancel={() => setCreateVisible(false)}
      >
        <UserCreate onFinish={handleCreateFinish} />
      </Modal>
      <PageHeader
        style={{ paddingLeft: 0, paddingRight: 0 }}
        title="Users"
        extra={[
          <Button
            onClick={() => setCreateVisible(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create New
          </Button>,
        ]}
      />

      <Table
        // TODO: Add loader for users fetch
        // loading={
        //   status === "loading"
        //     ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
        //     : false
        // }
        dataSource={users}
        pagination={false}
        columns={[
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            sorter: true,
            width: "40%",
            ...getConfigForFirstNameSearch(),
            render: (value: string, record: User) => {
              return `${record.firstName} ${record.lastName}`;
            },
          },
          {
            title: "Email",
            key: "name",
            dataIndex: "name",
            sorter: true,
            width: "40%",
            ...getConfigForFirstNameSearch(),
            render: (value: string, user: User) => {
              return `${user.firstName} ${user.lastName}`;
            },
          },
          {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
            render: (value, user) => (
              <Button
                type="link"
                // onClick={() => {
                //   setCurrentPlatform(platform.id);
                //   setModalMode("edit");
                // }}
              >
                Permissions
              </Button>
            ),
          },
        ]}
      />
    </Layout>
  );
};
