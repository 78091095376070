import { ButtonProps, Button, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import { FC, useState } from "react";
import { canUpdateUser } from "../../common";
import { UserForm } from "./UserForm";
import { EditOutlined } from "@ant-design/icons";
import { useUserDetails } from "./UserDetailsContext";

export const UserUpdateButton: FC<
  ButtonProps & { onSuccess?: () => void; onError?: (e: any) => void }
> = (props) => {
  const { disabled, onSuccess, onError, ...buttonProps } = props;
  const { user, allowedActions, refetch } = useUserDetails();
  const [visible, setVisible] = useState(false);
  const handleFinish = () => {
    refetch();
    setVisible(false);
  };

  if (!canUpdateUser(allowedActions)) {
    return null;
  }

  return (
    <>
      <Modal
        visible={visible}
        destroyOnClose
        onCancel={() => setVisible(false)}
        footer={false}
        title="Update User"
      >
        <UserForm mode="edit" onFinish={handleFinish} initialValues={user} />
      </Modal>
      <Tooltip title="Edit User">
        <Button
          type="primary"
          {...buttonProps}
          disabled={disabled}
          onClick={() => setVisible(true)}
          icon={<EditOutlined />}
        />
      </Tooltip>
    </>
  );
};
