export const COLORED_LOGO = "https://cdn.pulpo.me/logo_apqcsv.png";
export const WHITE_LOGO =
  "https://res.cloudinary.com/pulpo/image/upload/v1616147307/admin-web/WhiteLogo_qt88th.png";
export const STANDARD_PASSWORD = `Pulpo@${new Date().getFullYear()}`;
export const ROUTES = {
  getGroupDetailsRoute: (identifier: string = ":identifier") => `/group/${identifier}`,
  getUserDetailsRoute: (userId: string = ":userId") => `/user/${userId}`,
  getBulkTaggingRoute: () => `/bulk-tagging`
}

export const USER_STORAGE_KEY = "user";
export const RELATIONSHIP_ITEM_PER_PAGE = 20;
export const GROUP_MEMBER_ALLOWED_ACTION = "group_member_remove";
export const GROUP_CACHE_KEYS = {
  COMMUNICATIONS: (mask: string) => `group-communication-${mask}`,
  MEMBERS: (mask: string) => `group-members-${mask}`,
  ACTIONS: (mask: string) => `group-group-actions-${mask}`,
}
export const USER_CACHE_KEYS = {
  COMMUNICATIONS: (userId: number) => `user-communication-${userId}`,
  MEMBERS: (userId: number) => `user-group-members-${userId}`,
  ACTIONS: (userId: number) => `user-group-actions-${userId}`,
  FINANCE_ACCOUNTS: (userId: number) => `user-finance-accounts-${userId}`,
  FINANCE_TRANSACTIONS: (userId: number) => `user-finance-transactions-${userId}`,
  GROUPS_AS_OWNER: (userId: number) => `groups-as-owner-${userId}`,
  USER_PAYMENT_METHODS: (userId: number) => `user-payment-methods-${userId}`,
}