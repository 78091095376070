import { RouteComponentProps } from "@reach/router";
import { Typography, Skeleton, Row, Col } from "antd";
import { FC } from "react";
import { Layout, RouteParamMissing } from "../../common";
import { GroupProvider, useGroup } from "./GroupContext";
import { GroupInfo } from "./GroupInfo";
import { GroupDetailedData } from "./GroupDetailedData";

export const GroupDetailsWrapper: FC<
  RouteComponentProps & { identifier?: string }
> = (props) => {
  const { identifier } = props;
  if (!identifier) {
    throw new RouteParamMissing("group identifier");
  }
  return (
    <GroupProvider identifier={identifier}>
      <GroupDetails />
    </GroupProvider>
  );
};

const GroupDetails: FC = (props) => {
  const { isLoading, group, error, refetch, allowedActions } = useGroup();
  return (
    <Layout title={""}>
      {isLoading ? <Skeleton /> : null}
      {group ? (
        <Row>
          <Col span={24}>
            <GroupInfo
              group={group}
              refetch={refetch}
              allowedActions={allowedActions}
            />
            <GroupDetailedData
              mask={group.mask}
              disableCredentialsTab={
                group.platform.type !== "credentials_sharing"
              }
            />
          </Col>
        </Row>
      ) : null}
      {error ? (
        error.status === 404 ? (
          <Row align="middle" justify="center" style={{ marginTop: "20vh" }}>
            <Typography.Title level={4}>Group No Found!</Typography.Title>
          </Row>
        ) : (
          <Typography.Paragraph>
            Some error occurred. {error.statusText}
            <br />
            Please contact{" "}
            <a href="mailto:rajat@pulpo.club">rajat@pulpo.club</a>
          </Typography.Paragraph>
        )
      ) : null}
    </Layout>
  );
};
