import {
  Button,
  Form,
  InputNumber,
  DatePicker,
  Input,
  notification,
  Typography,
  Modal,
} from "antd";
import { FC } from "react";
import { useMutation } from "react-query";
import { useConfig } from "../../auth/components/ConfigContext";
import { APIError } from "../../common";
import { addRemoveCredit as addRemoveCreditAPI } from "../../common/api/users";

export const UpdateBalanceForm: FC<{
  onFinish?: () => void;
  userId: number;
  mode: "credit" | "debit";
}> = (props) => {
  const { onFinish, userId, mode } = props;
  const { formatCurrency } = useConfig();
  const { mutate: addRemoveCredit, isLoading } =
    useMutation(addRemoveCreditAPI);

  const handleFinish = (values: any) => {
    console.log(values);
    Modal.confirm({
      title: `Are you sure you want to ${mode} ${formatCurrency(
        values.amount
      )}?`,
      content:
        "NOTE: This actions is permanent, make sure the amount you entered is 100% correct",
      onOk: () => {
        addRemoveCredit(
          {
            ...values,
            entryType: mode,
            userId,
          },
          {
            onSuccess: () => {
              notification["success"]({
                message: "Balance Updated Successfully",
              });
              onFinish?.();
            },
            onError: (e) => {
              const error = e as APIError;
              let message =
                "Something when wrong. Please contact rajat@pulpo.club";
              if (error.data?.errors.validator) {
                message = Object.values(error.data.errors.validator)[0][0];
              } else if (error.data?.errors.message) {
                message = error.data.errors.message;
              }
              notification.error({
                message,
              });
            },
          }
        );
      },
    });
  };
  return (
    <Form onFinish={handleFinish} layout="vertical">
      <Form.Item
        required
        rules={[{ required: true }]}
        name="amount"
        label="Amount"
      >
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Amount"
          decimalSeparator=","
          // formatter={currencyFormatter(
          //   currentCountry.defaultLanguage,
          //   currentCountry.defaultCurrency
          // )}
          // parser={currencyParser(
          //   currentCountry.defaultLanguage,
          //   currentCountry.defaultCurrency
          // )}
          step={0.5}
        />
      </Form.Item>

      {mode === "credit" ? (
        <Form.Item
          name="onHoldUntil"
          label={
            <Typography.Text>
              Hold Until <span style={{ fontSize: 10 }}>(Optional)</span>{" "}
            </Typography.Text>
          }
        >
          <DatePicker
            placeholder="Hold Until"
            disabledDate={(date) => +date < Date.now()}
            showTime={false}
          />
        </Form.Item>
      ) : null}

      <Form.Item
        name="description"
        label="Visible message sent to the user"
        required
        rules={[{ required: true }]}
      >
        <Input.TextArea placeholder="Notes" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
