import { FC, useState } from "react";
import { UserForm } from "./UserForm";
import { PermissionsForm } from "./PermissionsForm";

export const UserCreate: FC<{
  onFinish?: () => void;
}> = (props) => {
  const { onFinish } = props;
  const [step, setStep] = useState<"user" | "permissions">("user");
  const [userId, setUserId] = useState<number | null>(null);
  if (step === "user") {
    return (
      <UserForm
        onFinish={(id: number) => {
          setStep("permissions");
          setUserId(id);
        }}
      />
    );
  } else if (step === "permissions" && userId) {
    return <PermissionsForm onFinish={onFinish} userId={userId} />;
  } else {
    // Ideally this should never happen
    return null;
  }
};
