import * as Sentry from "@sentry/react";

/**
 * NOTE: Purposefully not creating the setItem functions
 * Ideally localStorage should not set directly
 * getItem is only used to access storage outside of the components
 */

export const getItem = <T>(key: string, defaultValue?: T) => {
  try {
    const value = JSON.parse(localStorage.getItem(key)!) as T;
    return value;
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        key,
        defaultValue: String(defaultValue)
      }
    });
    return null;
  }
}

export const clearStorage = () => {
  try {
    localStorage.clear()
  } catch (e) {
    Sentry.captureException(e);
  }
}