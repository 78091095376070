import React, { FC, useCallback, useContext, useMemo } from "react";
import { ContextNotFoundError, User, USER_STORAGE_KEY } from "../../common";
import createPersistedState from "use-persisted-state";
import { useConfig } from "./ConfigContext";

type UserWithToken = User & { token: string };
type UserContextType = {
  user: UserWithToken | null;
  setUser: (user: UserWithToken) => void;
  deleteUser: () => void;
};

const UserContext = React.createContext<UserContextType | undefined>(undefined);

const useUserState = createPersistedState(USER_STORAGE_KEY);
export const UserProvider: FC = (props) => {
  const { countries, setCurrentCountry } = useConfig();
  const [user, setUser] = useUserState<UserWithToken | null>(null);
  const deleteUser = useCallback(() => {
    setUser(null);
  }, [setUser]);
  const handleSetUser = useCallback(
    (user: UserWithToken) => {
      const country =
        countries.find((country) =>
          country.languages.includes(user.language)
        ) || countries[0];
      setCurrentCountry(country.id);
      setUser(user);
    },
    [setCurrentCountry, setUser, countries]
  );
  const contextValue = useMemo(
    () => ({ user, setUser: handleSetUser, deleteUser }),
    [user, deleteUser, handleSetUser]
  );
  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const contextValue = useContext(UserContext);

  if (typeof contextValue === "undefined") {
    throw new ContextNotFoundError("User");
  }

  return contextValue;
};
