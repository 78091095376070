import { ButtonProps, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { FC, useState } from "react";
import { canEditPermissions, canSeePermissions } from "../../common";
import { PermissionsForm } from "./PermissionsForm";
import { useUserDetails } from "./UserDetailsContext";

export const ManagePermissionsButton: FC<
  ButtonProps & { onSuccess?: () => void; onError?: (e: any) => void }
> = (props) => {
  const { disabled, onSuccess, onError, ...buttonProps } = props;
  const { user, allowedActions } = useUserDetails();
  const [visible, setVisible] = useState(false);

  if (!canSeePermissions(allowedActions)) {
    return null;
  }

  return (
    <>
      <Modal
        visible={visible}
        destroyOnClose
        onCancel={() => setVisible(false)}
        footer={false}
        title="Manage Permissions"
      >
        <PermissionsForm
          userId={user?.id!}
          disabled={!canEditPermissions(allowedActions)}
          onFinish={() => setVisible(false)}
        />
      </Modal>
      <Button
        type="primary"
        {...buttonProps}
        disabled={disabled}
        onClick={() => setVisible(true)}
      >
        Permissions
      </Button>
    </>
  );
};
